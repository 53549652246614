import { Component, OnInit, Input } from '@angular/core';
import { valueToString } from '../../../../Backend/src/GlobalFunction';

@Component({
  selector: 'app-coprocessor',
  templateUrl: './coprocessor.component.html',
  styleUrls: ['./coprocessor.component.css']
})
export class CoprocessorComponent implements OnInit {

  _cause: string;
  _status: string;
  _elr: string;
  _oldStatus: string;

  @Input() set cause(value: number) {
    this._cause = valueToString(BigInt.asUintN(4, BigInt(value)), 16, 0.5);
  }
  @Input() set status(value: bigint) {
    this._status = valueToString(value, 16, 8);
  }
  @Input() set elr(value: number) {
    this._elr = valueToString(value, 16, 8);
  }
  @Input() set oldStatus(value: bigint) {
    this._oldStatus = valueToString(value, 16, 8);
  }


  constructor() { }

  ngOnInit() {
  }

}
