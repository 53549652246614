import CoProcessor0 from "./CoProcessor0";

export default class Timer {
    private _coProcessor0: CoProcessor0;
    private _stepsTillInterrupt: number = undefined;

    get stepsTillInterrupt(): number {
        return this._stepsTillInterrupt;
    }

    constructor(coProcessor: CoProcessor0) {
        this._coProcessor0 = coProcessor;
    }

    step(): void {
        if (this._stepsTillInterrupt > 0) {
            this._stepsTillInterrupt--;
        }
        if (this._stepsTillInterrupt === 0) {
            this._stepsTillInterrupt = undefined;
            this._fireInterrupt();
        }
    }

    setTimer(stepsTillInterrupt: number): void {
        if (stepsTillInterrupt === 0) {
            this._stepsTillInterrupt = undefined;
            this._fireInterrupt();
        } else {
            this._stepsTillInterrupt = stepsTillInterrupt;
        }
    }

    private _fireInterrupt(): void {
        this._coProcessor0.registerInterrupt(1);
    }
}