import { MemoryRegionToStringResultInterface } from './MemoryRegionToStringResultInterface';
import { OsReadError } from './OsReadError';
import { ReadonlyError } from './ReadonlyError';
import MemoryRegionData from "./MemoryRegionData";
import IoDeviceInterface from './IoDeviceInterface';
import MemoryRegionInterface from './MemoryRegionInterface';
import MemoryRegionIoDeviceHandler from './MemoryRegionIoDeviceHandler';

export const DEFAULT_OS_START_ADDRESS: number = Number("0x000C0900");
export const DEFAULT_TEXT_START_ADDRESS: number = Number("0x400000");
export const DEFAULT_GLOBAL_DATA_START_ADDRESS: number = Number("0x10000000");
export const STACK_LAST_ADDRESS: number = Number("0x7FFFFFFFFF");
export const IO_REGION_START_ADDRESS: number = Number("0x800000000000");

export const DEFAULT_STACK_SIZE: number = 64;
export const DEFAULT_HEAP_SIZE: number = 64;

export default class Memory {
    private _memoryRegions: Map<string, MemoryRegionInterface> = new Map();
    private _littleEndian: boolean;
    private _lastKernelReadOnlyAddress: number;
    private _lastReadOnlyAddress: number;

    private _finishedCompiling: boolean = false;

    get memoryRegions(): Map<string, MemoryRegionInterface> {
        return this._memoryRegions;
    }

    constructor(osTextSizeInByte: number, osDataSizeInByte: number,
        textStartAddress: number, textSizeInByte: number,
        globalDataStartAddress: number, globalDataSizeInByte: number,
        heapSizeInByte: number,
        stackSizeInByte: number,
        ioDevices: IoDeviceInterface[],
        littleEndian: boolean)
    {
        this._littleEndian = littleEndian;

        // osRegion
        this._lastKernelReadOnlyAddress = DEFAULT_OS_START_ADDRESS + osTextSizeInByte;
        this._memoryRegions.set("kernel", new MemoryRegionData(DEFAULT_OS_START_ADDRESS, osTextSizeInByte + osDataSizeInByte));

        // text
        textStartAddress = textStartAddress ? textStartAddress : DEFAULT_TEXT_START_ADDRESS;
        if (textStartAddress <= this._memoryRegions.get("kernel").lastAddress) {
            throw new RangeError("Text Segment overlaps with reserved Operating System Memory. First free Memory entry at" + (this._memoryRegions.get("kernel").lastAddress + 1));
        }
        this._memoryRegions.set("text", new MemoryRegionData(textStartAddress, textSizeInByte));
        this._lastReadOnlyAddress = textStartAddress + textSizeInByte;

        // global Data
        globalDataStartAddress = globalDataStartAddress ? globalDataStartAddress : DEFAULT_GLOBAL_DATA_START_ADDRESS;
        globalDataSizeInByte = globalDataSizeInByte ? globalDataSizeInByte : 0;
        if (globalDataStartAddress <= this._memoryRegions.get("text").lastAddress) {
            throw new RangeError("Global Data Segment overlaps with Programm Memory. First free Memory entry at" + (this._memoryRegions.get("text").lastAddress + 1));
        }
        this._memoryRegions.set("data + heap", new MemoryRegionData(globalDataStartAddress, globalDataSizeInByte + heapSizeInByte));

        // stack
        const stackStartAddress: number = STACK_LAST_ADDRESS - stackSizeInByte + 1;
        if (stackStartAddress <= this._memoryRegions.get("data + heap").lastAddress) {
            throw new RangeError("Requested Stacksize overlaps with Global Memory. Place GlobalData at lower address, or shrink size of GlobalData or Stack by " + (this._memoryRegions.get("data + heap").lastAddress - stackStartAddress + 1));
        }
        this._memoryRegions.set("stack", new MemoryRegionData(stackStartAddress, stackSizeInByte));

        // if stack & io overlap throw
        this._memoryRegions.set("memory mapped i/o", new MemoryRegionIoDeviceHandler(ioDevices, IO_REGION_START_ADDRESS));
    }

    load(address: number, byteAmount: number, inKernelMode: boolean): bigint {
        if (!inKernelMode) this._checkOsRead(address);

        return this.toMemoryRegion(address).load(address, byteAmount, this._littleEndian);
    }

    store(address: number, value: bigint, byteAmount: number, inKernelMode: boolean): void {
        this._checkReadOnly(address, inKernelMode);

        this.toMemoryRegion(address).store(address, value, byteAmount, this._littleEndian);
    }

    // loads from address next chars till \0
    loadAsString(address: number): string {
        let result: string = "";
        let currentChar: string = String.fromCharCode(Number(this.load(address, 1, true)));
        let i: number = 1;

        while (currentChar !== "\0") {
            result += currentChar;
            currentChar = String.fromCharCode(Number(this.load(address + i, 1, true)));
            i++;
        }
        return result;
    }

    toString(base: number, hideZeros: boolean = false): MemoryRegionToStringResultInterface[] {
        const result: MemoryRegionToStringResultInterface[] = [];
        for (const memoryRegion of this._memoryRegions) {
            const name: string = memoryRegion[0];
            const startAddress: number = memoryRegion[1].startAddress;
            const values: string[] = memoryRegion[1].toString(base, hideZeros);
            result.push({name, startAddress, values});
        }
        return result;
    }

    finishedCompiling(): void {
        this._finishedCompiling = true;
    }

    private toMemoryRegion(address: number): MemoryRegionInterface {
        for (const memoryRegion of this._memoryRegions.values()) {
            if (memoryRegion.startAddress <= address && address <= memoryRegion.lastAddress) {
                return memoryRegion;
            }
        }

        throw new RangeError("No Memory allocated at 0x" + address.toString(16));
    }

    private _checkReadOnly(address: number, inKernelMode: boolean): void {
        if (!this._finishedCompiling) {
            return;
        }

        if (inKernelMode) {
            if (this._memoryRegions.get("kernel").startAddress <= address
                && address <= this._lastKernelReadOnlyAddress)
            {
            throw new ReadonlyError("Memory at " + address + " is readonly.");
            }
        } else {
            if ((this._memoryRegions.get("kernel").startAddress <= address
                && address <= this._memoryRegions.get("kernel").lastAddress)
            || (this._memoryRegions.get("text").startAddress <= address
                && address <= this._memoryRegions.get("text").lastAddress))
            {
                throw new ReadonlyError("Memory at " + address + " is readonly.");
            }
        }
    }

    private _checkOsRead(address: number): void {
        if (this._finishedCompiling &&
                (this._memoryRegions.get("kernel").startAddress <= address
                 && address <= this._memoryRegions.get("kernel").lastAddress)) {
            throw new OsReadError();
        }
    }
}