<div class="top-content-bottom-grid">
    <!-- Top bar -->
    <div class="top-bar"> 
        <h1 id="heading" (click)="reload()" title="reload page">Mini-ARM-Simulator</h1> 
        <!-- <button (click)="debugg()">debugg</button> -->
    </div>

    <!-- content -->
    <div class="content"> 
        <div class="            invisible-scrollbar" id="memory"      > 
            <app-memory         [memory]="processor?.memory"        ></app-memory> 
        </div>
        
        <div class="invisible-scrollbar" id="registers"   *ngIf="processor">
            <app-registers      [registers]="processor.registers"  ></app-registers>
        </div>

        <div id="code-sp-reg-console">
            <div class="            invisible-scrollbar" id="code"        > 
                <app-code           [simulationHandler]="simulationHandler" 
                                    (compileEvent)="compile($event)" 
                                    [oldPc]="processor?.oldPc" 
                                    [pc]="processor?.pc"></app-code> 
            </div>

            <div id="sp-reg-console">
                <div id="special-register" *ngIf="processor">
                    <div id="alu-coprocessor">
                        <div id="alu"><app-alu-flags    [flagC]="processor.flagC"
                                                        [flagN]="processor.flagN"
                                                        [flagV]="processor.flagV"
                                                        [flagZ]="processor.flagZ"></app-alu-flags></div>

                        <div id="coprocessor"><app-coprocessor  [cause]="processor.coprocessor.cause"
                                                                [elr]="processor.coprocessor.elr"
                                                                [oldStatus]="processor.coprocessor.oldStatus"
                                                                [status]="processor.coprocessor.status"></app-coprocessor></div>

                    </div>

                    <div id="timer"><app-timer  [stepsTillInterrupt]="simulationHandler.timerStepsTillInterrupt"
                                                (setTimerEvent)="simulationHandler.setTimer($event)"></app-timer></div>
                </div>

                <app-console id="console" *ngIf="processor" [consoleOutput]="processor.consoleOutput" [consoleInput]="processor.consoleInput" (consoleInputChange)="setConsoleInput($event)"></app-console> 
            </div>

            
        </div>

        
    </div>

    <!-- bottom bar -->
    <div class="bottom-bar"> 
        <div class="grow-shrink only-desktop"></div>
        <div class="next-instruction" *ngIf="processor;else emptyDiv" title="next instruction executed">{{ toAddress(this.processor.pc) }} {{ this.processor?.nextInstructionString }}</div> 
        <ng-template #emptyDiv><div></div></ng-template>
        <div class="grow-shrink only-desktop"></div>
        <div class="three-buttons">
            <button (click)="onClickToBreakpoint()" *ngIf="processor" title="execute to next breakpoint">breakpt.</button>
            <button (click)="onClickSingleStep()"   *ngIf="processor" title="execute next instruction"  >step</button>
            <button (click)="onClickExecuteAll()"   *ngIf="processor" title="execute all instructions"  >all</button>
        </div>
    </div>

    <div class="only-desktop">
        <div class="footer">
        <a href="./Mini-ARM-Simulator__Das_Handbuch.pdf" title="Handbuch" target="_blank">Handbuch</a>
        <a href="https://www.uni-kl.de/impressum" title="Legal Notice" target="_blank">Impressum</a>
            <a href="https://www.uni-kl.de/datenschutzerklaerung/" target="_blank">Privacy Policy</a>
        </div>
    </div>
</div>

<div class="only-mobile">
    <div class="footer">
        <a href="./Mini-ARM-Simulator__Das_Handbuch.pdf" title="Handbuch" target="_blank">Handbuch</a>
        <a href="https://www.uni-kl.de/impressum" title="Legal Notice" target="_blank">Impressum</a>
        <a href="https://www.uni-kl.de/datenschutzerklaerung/" target="_blank">Privacy Policy</a>
    </div>
</div>