<div class="arm-sim-box">
  <h2>Timer</h2>

  <div class="text">
    interrupts in 
  </div>
  
  <input id="timer-input" type="number" [(ngModel)]="stepsTillInterrupt" (change)="onInputChange()" min="0" max="9999" title="steps till timer interrupts">

  <div class="text">steps</div>
</div>
