import { valueToString } from "./GlobalFunction";
import MemoryRegionInterface from "./MemoryRegionInterface";

BigInt.prototype.toJSON = function (): string { return this.toString() }

export default class MemoryRegionData implements MemoryRegionInterface {
    private _buffer: ArrayBuffer;
    private _sizeInByte: number;
    private _startAddress: number;
    private _view: DataView;

    get startAddress(): number {
        return this._startAddress;
    }

    get lastAddress(): number {
        return this._startAddress + this._sizeInByte - 1;
    }

    constructor(startAddress: number, sizeInByte: number) {
        this._startAddress = startAddress;
        this._sizeInByte = sizeInByte;
        this._buffer = new ArrayBuffer(sizeInByte);
        this._view = new DataView(this._buffer);
    }

    load(address: number, byteAmount: number, littleEndian: boolean): bigint {
        if (byteAmount === 1) return BigInt(this._view.getUint8(address - this._startAddress));
        if (byteAmount === 2) return BigInt(this._view.getUint16(address - this._startAddress, littleEndian));
        if (byteAmount === 4) return BigInt(this._view.getUint32(address - this._startAddress, littleEndian));
        if (byteAmount === 8) {
            let bits63to32: bigint;
            let bits31to0: bigint;
            if (littleEndian) {
                bits63to32 = this.load(address + 4, 4, littleEndian);
                bits31to0 = this.load(address, 4, littleEndian);
            } else {
                bits63to32 = this.load(address, 4, littleEndian);
                bits31to0 = this.load(address + 4, 4, littleEndian);
            }
            return BigInt.asUintN(64, (bits63to32 << BigInt(32)) + bits31to0);
        }
    }

    store(address: number, value: bigint, byteAmount: number, littleEndian: boolean): void {
        if (byteAmount === 1) this._view.setUint8(address - this._startAddress, Number(BigInt.asIntN(8, value)));
        if (byteAmount === 2) this._view.setUint16(address - this._startAddress, Number(BigInt.asIntN(16, value)), littleEndian);
        if (byteAmount === 4) this._view.setUint32(address - this._startAddress, Number(BigInt.asIntN(32, value)), littleEndian);
        if (byteAmount === 8) {
            const bits63to32: bigint = BigInt.asUintN(64, value) >> BigInt(32);
            const bits31to0: bigint = BigInt.asUintN(32, value);
            if (littleEndian) {
                this.store(address + 4, bits63to32, 4, littleEndian);
                this.store(address, bits31to0, 4, littleEndian);
            } else {
                this.store(address, bits63to32, 4, littleEndian);
                this.store(address + 4, bits31to0, 4, littleEndian);
            }
        }
    }

    toString(base: number, hideZeros: boolean): string[] {
        const result: string[] = [];
        for (let i: number = 0; i < this._sizeInByte; i++) {
            const value: number = this._view.getUint8(i);
            const str: string = valueToString(value, base, 1, hideZeros);
            result.push(str);
        }
        return result;
    }
}