import CoProcessor0 from "./CoProcessor0";

export default class Alu {
    private _lastResult64U: bigint = BigInt(0);       // saved as unsigned int
    private _lastRegisterLeftU: bigint = BigInt(0);   // saved as unsigned int
    private _lastRegisterRightU: bigint = BigInt(0);  // saved as unsigned int

    private _coprocessor0: CoProcessor0;

    constructor(coprocessor0: CoProcessor0) {
        this._coprocessor0 = coprocessor0;
    }

    private _calcFlagsZN(): void {
        this._coprocessor0.flagZ = (this._lastResult64U === BigInt(0));
        this._coprocessor0.flagN = (BigInt.asIntN(64, this._lastResult64U) < BigInt(0));
    }

    private _calcFlagsCV(): void {
        this._coprocessor0.flagC = this._isCarry(); // calc flagC ALWAYS before flagV, since flagV needs result of flagC
        this._coprocessor0.flagV = this._isOverflow();
        if (this._coprocessor0.flagV) this._coprocessor0.registerInterrupt(12);
    }

    private _isCarry(): boolean {
        return BigInt.asIntN(65, BigInt.asUintN(64, this._lastRegisterLeftU) + BigInt.asUintN(64, this._lastRegisterRightU)) < BigInt(0);
    }

    private _isOverflow(): boolean {
        const a63: boolean = (BigInt.asIntN(64, this._lastRegisterLeftU) < BigInt(0));
        const b63: boolean = (BigInt.asIntN(64, this._lastRegisterRightU) < BigInt(0));
        const c64: boolean = this._coprocessor0.flagC;
        const s63: boolean = (BigInt.asIntN(64, this._lastResult64U) < BigInt(0));
        return (a63 === b63) && (c64 ? !s63 : s63);
    }

    private _resetCV(): void {
        this._coprocessor0.flagC = false;
        this._coprocessor0.flagV = false;
    }

    //#region ======= Arithmetik =======

    adds(summandA: bigint, summandB: bigint): bigint {
        this.add(summandA, summandB);
        this._calcFlagsZN();
        this._calcFlagsCV();
        return this._lastResult64U;
    }

    add(summandA: bigint, summandB: bigint): bigint {
        this._lastRegisterLeftU = BigInt.asUintN(64, summandA);
        this._lastRegisterRightU = BigInt.asUintN(64, summandB);
        this._lastResult64U  = BigInt.asUintN(64, this._lastRegisterLeftU + this._lastRegisterRightU);
        return this._lastResult64U;
    }

    sub(minuend: bigint, subtrahend: bigint): bigint {
        return this.add(minuend, -subtrahend);
    }

    subs(minuend: bigint, subtrahend: bigint): bigint {
        return this.adds(minuend, -subtrahend);
    }

    mul(factorA: bigint, factorB: bigint): bigint {
        return BigInt.asUintN(64, factorA * factorB);
    }

    sdiv(dividend: bigint, divisor: bigint): bigint {
        try {
            return BigInt.asUintN(64, BigInt.asIntN(64, dividend) / BigInt.asIntN(64, divisor));
        } catch (RangeError) {
            this._coprocessor0.registerInterrupt(13);
            return undefined;
        }
    }

    //#endregion

    //#region ======= Logic      =======
    and(valueA: bigint, valueB: bigint): bigint {
        this._lastResult64U = BigInt.asUintN(64, BigInt.asUintN(64, valueA) & BigInt.asUintN(64, valueB));
        return this._lastResult64U;
    }

    ands(valueA: bigint, valueB: bigint): bigint {
        this.and(valueA, valueB);
        this._calcFlagsZN();
        this._resetCV();
        return this._lastResult64U;
    }

    orr(valueA: bigint, valueB: bigint): bigint {
        return BigInt.asUintN(64, BigInt.asUintN(64, valueA) | BigInt.asUintN(64, valueB));
    }

    eor(valueA: bigint, valueB: bigint): bigint {
        return BigInt.asUintN(64, BigInt.asUintN(64, valueA) ^ BigInt.asUintN(64, valueB));
    }

    orn(valueA: bigint, valueB: bigint): bigint {
        return BigInt.asUintN(64, ~this.orr(valueA, valueB));
    }

    //#endregion

    //#region ======= Shift      =======
    lsl(value: bigint, shiftAmount: bigint): bigint {
        return BigInt.asUintN(64, BigInt.asUintN(64, value) << BigInt.asUintN(6, shiftAmount));
    }

    lsr(value: bigint, shiftAmount: bigint): bigint {
        return BigInt.asUintN(64, BigInt.asUintN(64, value) >> BigInt.asUintN(6, shiftAmount));
    }

    asr(value: bigint, shiftAmount: bigint): bigint {
        shiftAmount = BigInt.asUintN(6, shiftAmount);
        return BigInt.asUintN(64, BigInt.asIntN(64 + Number(shiftAmount), value) >> shiftAmount );
    }

    ror(value: bigint, shiftAmount: bigint): bigint {
        shiftAmount = BigInt.asUintN(6, shiftAmount);
        const oldRightBits: bigint = BigInt.asUintN(Number(shiftAmount), value);
        const oldLeftBits: bigint = this.lsr(value, shiftAmount);
        return BigInt.asUintN(64, this.lsl(oldRightBits, BigInt(64)-shiftAmount) + oldLeftBits);
    }

    //#endregion

}