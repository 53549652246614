import { DEFAULT_HEAP_SIZE, DEFAULT_STACK_SIZE } from './Memory';
import CoProcessor0 from './CoProcessor0';
import Processor from './Processor';
import Compiler from './Compiler';
import Timer from './Timer';

const START_AMOUNT_OF_SIMULATION_STEPS: number = 5000;

export default class SimulationStepHandler {
    private _simulationStep: number = 0;
    private _currentSimulationStepLimit: number = START_AMOUNT_OF_SIMULATION_STEPS;
    private _breakpoints: number[] = [];

    private _processor: Processor;
    private _coprocessor0: CoProcessor0;
    private _timer: Timer;

    //#region getter setter

    get pc(): number {
        return this._processor.pc;
    }

    get breakpoints(): number[] {
        return this._breakpoints;
    }

    get coprocessor0(): CoProcessor0 {
        return this._coprocessor0;
    }

    get processor(): Processor {
        return this._processor;
    }

    get timerStepsTillInterrupt(): number {
        return this._timer.stepsTillInterrupt;
    }

    //#endregion

    // returns linenumbers, where empty line needs to be inserted
    compile(code: string, heapSize: number = DEFAULT_HEAP_SIZE, stackSize: number = DEFAULT_STACK_SIZE, littleEndian: boolean = false): number[] {
        const compiler: Compiler = new Compiler(code, heapSize, stackSize, littleEndian);
        compiler.compile();
        this._processor = compiler.processor;
        this._coprocessor0 = this._processor.coprocessor;
        this._timer = new Timer(this._coprocessor0);
        return compiler.editorLinesToInsertEmptyLines;
    }

    // returns whether breakpoint at given line is set
    toggleBreakpoint(address: number): boolean {
        const index: number = this._breakpoints.indexOf(address);
        if (index === -1) {
            this._breakpoints.push(address);
            return true;
        } else {
            this._breakpoints.splice(index, 1);
            return false;
        }
    }

    setTimer(stepsTillInterrupt: number): void {
        if (!this._timer) {
            throw new Error("You have to compile before you can set a timer interrupt.");
        }
        this._timer.setTimer(stepsTillInterrupt);
    }

    executeAll(): void {
        while (this._processor.nextInstruction !== undefined) {
            this.executeSingleStep();
        }
    }

    executeToNextBreakpoint(): void {
        this.executeSingleStep();
        while (!this._reachedBreakpoint() && this._processor.nextInstruction !== undefined) {
            this.executeSingleStep();
        }
    }

    executeSingleStep(): void {
        this._increaseSimulationStep();

        this._processor.ioDeviceConsoleInput.step();
        this._processor.ioDeviceConsoleOutput.step();
        this._processor.step();
        this._coprocessor0.step();
        this._timer.step();
    }

    private _increaseSimulationStep(): void {
        this._simulationStep++;
        if (this._currentSimulationStepLimit < this._simulationStep) {
            this._simulationStep--;
            this._currentSimulationStepLimit += this._currentSimulationStepLimit;
            throw new Error(`You have just simulated up to step ${this._simulationStep}. The next auto break will be at step ${this._currentSimulationStepLimit}`);
        }
    }

    private _reachedBreakpoint(): boolean {
        return this._breakpoints.some((value): boolean => {
            return value === this.pc;
        });
    }
}