import { REGEXP_KERNEL_TEXT } from './../../cm-mode-MiniARM';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import Instruction from '../../../../Backend/src/Instruction';
import { initMiniArmMode } from 'src/cm-mode-MiniARM';
import { DEFAULT_INTERRUPT_HANDLER } from '../../../../Backend/src/DefaultInterruptHandler';
import { toAddress } from '../global-functions';
import SimulationStepHandler from '../../../../Backend/src/SimulationStepHandler';
import { TextMarker } from 'codemirror';
import './../../../node_modules/codemirror/keymap/sublime';

const LINE_WITH_FILE_NAME: string = '// Name:';
const DEFAULT_FILE_NAME: string = 'your-mini-arm-code.txt';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.css']
})
export class CodeComponent implements AfterViewInit {
  @ViewChild('codeMirrorCompiledCode', {static: false}) private cmComponentCompiledCode: CodemirrorComponent;
  @ViewChild('codeMirrorInputCode', {static: false}) private cmComponentInputCode: CodemirrorComponent;

  _addressToLine: Map<number, number> = new Map<number, number>();
  private _lastLineExecuted: number = -1;
  private _nextLineExecuted: number = -1;
  private _greyedOut: TextMarker;
  private _errorUnderline: TextMarker;
  private _errorMessage: HTMLElement;

  usersCode: string = LINE_WITH_FILE_NAME + ' sum of 5 numbers \n' + `
		.text
		LA XS0, data	// data start address
		LI XS1, #5		// n
		LI XS2, #0		// i
		LI XS3, #0		// sum

loop:	LSL XT0, XS2, #3	// i * 8
		ADD XT1, XS0, XT0	// current address
		LDUR XT2, [XT1, #0]	// load value
		ADD XS3, XS3, XT2	// add value to sum
		ADDI XS2, XS2, #1	// increment i
		CMP XS2, XS1		// i < n ?
		B.LT loop

		LA A0, str		// string address
		LI A7, #4		// svc 4 (print_string)
		SVC				// print "The answer is "
		MOV A0, XS3		// move sum into argument
		LI A7, #1		// svc 1 (print_int)
		SVC				// print sum

		.data
data:   .doubleword 1 2 3 6 30
str:    .asciiz "The answer is "
  ` + DEFAULT_INTERRUPT_HANDLER;

  @Input() simulationHandler: SimulationStepHandler;
  @Output() compileEvent: EventEmitter<string> = new EventEmitter<string>(false);

  @Input() set oldPc(value: number) {
    if (value !== null) {
      this._removeHighlight(this._lastLineExecuted, 'cm-last-line');
      if (value !== undefined) {
        const line: number = this._addressToLine.get(value);
        if (line !== undefined) {
          this._lastLineExecuted = this._addressToLine.get(value) ? this._addressToLine.get(value) : -1;
          this._setHighlight(this._lastLineExecuted, 'cm-last-line');
        }
      }
    }
  }
  @Input() set pc(value: number) {
    if (value !== null) {
      this._removeHighlight(this._nextLineExecuted, 'cm-next-line');
      if (value !== undefined) {
        const line: number = this._addressToLine.get(value);
        if (line !== undefined) {
          this._nextLineExecuted =  line;
          this._setHighlight(this._nextLineExecuted, 'cm-next-line');
        }
      }
    }

    // this.jumpToLine(this._hightlightedLine);
  }

  constructor() { }

  ngAfterViewInit(): void {
    initMiniArmMode(this.cmComponentInputCode);
    this.cmComponentInputCode.codeMirror.setOption('mode', 'mini-ARM');
    this.cmComponentInputCode.codeMirror.setOption('viewportMargin', Infinity);
    this.cmComponentCompiledCode.codeMirror.setOption('mode', 'mini-ARM');

    this.cmComponentCompiledCode.codeMirror.on('gutterClick', (cm, lineNumber): void => {
      const info = cm.lineInfo(lineNumber);
      if (!this._isEmptyLine(info.gutterMarkers)) {
        const addressOfLine: number = Number('0x' + info.gutterMarkers['CodeMirror-addresses'].innerHTML.slice(1, -1)) ;
        const isBreakpointSet: boolean = this.simulationHandler.toggleBreakpoint(addressOfLine);
        cm.setGutterMarker(lineNumber, 'CodeMirror-breakpoints', isBreakpointSet ? this._makeMarker() : null);
      }
    });
    this.cmComponentInputCode.codeMirror.on('beforeChange', (): void => {
      if (!this._greyedOut) {
        this._greyedOut = this.cmComponentCompiledCode.codeMirror.markText({line: 0, ch: 0}, {line: this.cmComponentCompiledCode.codeMirror.lastLine() + 1, ch: 0}, {css: 'opacity: 0.3;'});
        this._removeLineHighlightInputCode();
      }
      this._clearErrorMessage();
    });
    this.cmComponentInputCode.codeMirror.addKeyMap({'Ctrl-S': (cm) => {this.onClickCompile(); }});
    this.cmComponentInputCode.codeMirror.addKeyMap({'Ctrl-7': (cm) => {this._onClickComment(); }});

    this.cmComponentInputCode.codeMirror.setValue(this.usersCode);
  }

  onClickCompile(): void {
    if (!this.cmComponentInputCode.codeMirror.getValue().match(REGEXP_KERNEL_TEXT)) {
      this.cmComponentInputCode.codeMirror.replaceRange(DEFAULT_INTERRUPT_HANDLER, {line: this.cmComponentInputCode.codeMirror.lineCount(), ch: 0});
      this.removeGreyedOut();
    }
    this.compileEvent.emit(this.cmComponentInputCode.codeMirror.getValue());
  }

  insertCompilationInformation(textInstructions: Instruction[], kernelInstructions: Instruction[], emptyLinesToBeInsertedIntoEditor: number[]): void {
    this.cmComponentCompiledCode.codeMirror.setValue('');
    this.cmComponentCompiledCode.codeMirror.clearHistory();

    this._insertInCompiledCode(textInstructions);
    this._insertInCompiledCode(kernelInstructions);

    this._stretchInputCode(emptyLinesToBeInsertedIntoEditor);

    this._addDotTextToCompiledCode();
  }

  removeGreyedOut(): void {
    if (this._greyedOut) {
      this._greyedOut.clear();
      this._greyedOut = null;
    }
  }

  uploadFile(): void {
    const file: File = (document.getElementById('input-file') as HTMLInputElement).files[0];
    file.text().then((value): void => {
      this.cmComponentInputCode.codeMirror.setValue(value);
    });
    file.text().catch((reason): void => {
      console.log(reason);
      alert('Something went wrong. Retry upload.');
    });
  }

  downloadFile(): void {
    const filename: string = this._getFileName();
    const text: string = this.cmComponentInputCode.codeMirror.getValue();

    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  private _removeHighlight(line: number, cssClass: string): void {
    this.cmComponentCompiledCode.codeMirror.removeLineClass(line, 'gutter', cssClass);
    this.cmComponentCompiledCode.codeMirror.removeLineClass(line, 'wrap', cssClass);
    this.cmComponentInputCode.codeMirror.removeLineClass(line, 'gutter', cssClass);
    this.cmComponentInputCode.codeMirror.removeLineClass(line, 'wrap', cssClass);
  }

  private _setHighlight(line: number, cssClass: string): void {
    this.cmComponentCompiledCode.codeMirror.addLineClass(line, 'gutter', cssClass);
    this.cmComponentCompiledCode.codeMirror.addLineClass(line, 'wrap', cssClass);
    if (!this._greyedOut) {
      this.cmComponentInputCode.codeMirror.addLineClass(line, 'gutter', cssClass);
      this.cmComponentInputCode.codeMirror.addLineClass(line, 'wrap', cssClass);
    }
  }

  private jumpToLine(line: number): void {
    const editor = this.cmComponentCompiledCode.codeMirror;
    const t = editor.charCoords({line, ch: 0}, 'local').top;
    const middleHeight = editor.getScrollerElement().offsetHeight / 2;
    editor.scrollTo(null, t - middleHeight - 5);
  }

  private _insertInCompiledCode(instructions: Instruction[]): void {
    instructions.forEach((instruction): void => {
      this._addressToLine.set(instruction.address, instruction.lineInEditor);

      while (this.cmComponentCompiledCode.codeMirror.lineCount() - 1 < instruction.lineInEditor ) {
        this.cmComponentCompiledCode.codeMirror.replaceRange('\n', {line: this.cmComponentCompiledCode.codeMirror.lineCount(), ch: 0});
      }
      this.cmComponentCompiledCode.codeMirror.replaceRange(instruction.toString(), {line: instruction.lineInEditor, ch: 0});
      this.cmComponentCompiledCode.codeMirror.setGutterMarker(instruction.lineInEditor, 'CodeMirror-addresses', this._makeMarkerAddress(instruction.address));
  });
  }

  private _stretchInputCode(emptyLinesToBeInsertedIntoEditor: number[]): void {
    emptyLinesToBeInsertedIntoEditor.forEach((value): void => {
      this.cmComponentInputCode.codeMirror.replaceRange('\n', {line: value, ch: 0});
    });
  }

  private _makeMarkerAddress(address: number): HTMLDivElement {
    const marker = document.createElement('div');
    marker.classList.add('CodeMirror-address');
    marker.innerHTML = toAddress(address, 4);
    return marker;
  }

  private _isEmptyLine(gutterMarkers: any): boolean {
    return gutterMarkers ? false : true;
  }

  private _makeMarker(): HTMLDivElement {
    const marker = document.createElement('div');
    marker.classList.add('CodeMirror-breakpoint');
    marker.innerHTML = '●';
    return marker;
  }

  setError(lineInEditor: number, errorMessage: string): void {
    this._clearErrorMessage();

    this._errorUnderline = this.cmComponentInputCode.codeMirror.markText({line: lineInEditor, ch: 0}, {line: lineInEditor, ch: 100}, {className: 'syntax-error'});

    this._errorMessage = document.createElement('div');
    this._errorMessage.setAttribute('class', 'cm-error-message');
    this._errorMessage.innerHTML = errorMessage;

    const divOfErrorLine: HTMLElement = document.getElementsByClassName('CodeMirror-code')[1].children[lineInEditor].children[1] as HTMLElement;
    divOfErrorLine.insertAdjacentElement('afterend', this._errorMessage);
  }

  private _removeLineHighlightInputCode(): void {
    this.cmComponentInputCode.codeMirror.removeLineClass(this._nextLineExecuted, 'gutter', 'cm-next-line');
    this.cmComponentInputCode.codeMirror.removeLineClass(this._nextLineExecuted, 'wrap', 'cm-next-line');
    this.cmComponentInputCode.codeMirror.removeLineClass(this._lastLineExecuted, 'gutter', 'cm-last-line');
    this.cmComponentInputCode.codeMirror.removeLineClass(this._lastLineExecuted, 'wrap', 'cm-last-line');
  }

  private _clearErrorMessage(): void {
    if (this._errorUnderline) {
      this._errorUnderline.clear();
      this._errorUnderline = null;
    }
    if (this._errorMessage) {
      this._errorMessage.remove();
      this._errorMessage = null;
    }
  }

  private _addDotTextToCompiledCode(): void {
    this.cmComponentCompiledCode.codeMirror.replaceRange('.text', {line: 0, ch: 0});
    this.cmComponentCompiledCode.codeMirror.markText({line: 0, ch: 0}, {line: 0, ch: 10}, {css: 'color: transparent;'});
  }

  private _onClickComment(): void {
    const firstLine: number = this.cmComponentInputCode.codeMirror.getCursor('from').line;
    const lastLine: number = this.cmComponentInputCode.codeMirror.getCursor('to').line;

    if (this._areAllLinesComments(firstLine, lastLine)) {
      // remove //
      for (let i = firstLine; i <= lastLine; i++) {
        const line: string = this.cmComponentInputCode.codeMirror.getLine(i);
        const index: number = line.indexOf('//');
        this.cmComponentInputCode.codeMirror.replaceRange('', {line: i, ch: index}, {line: i, ch: index + 2});
      }
    } else {
      // add //
      for (let i = firstLine; i <= lastLine; i++) {
        this.cmComponentInputCode.codeMirror.replaceRange('//', {line: i, ch: 0});
      }
    }
  }

  private _areAllLinesComments(firstLine: number, lastLine: number): boolean {
    for (let i = firstLine; i <= lastLine; i++) {
      if (!this.cmComponentInputCode.codeMirror.getLine(i).match(/^\s*\/\//)) {
        return false;
      }
    }
    return true;
  }

  private _getFileName(): string {
    let line: string = this.cmComponentInputCode.codeMirror.getLine(0);
    if (line.startsWith(LINE_WITH_FILE_NAME)) {
      line = line.slice(LINE_WITH_FILE_NAME.length);
      line = line.trim();
      line = line.replace(/\s/g, '_');
      line = (line === '') ? DEFAULT_FILE_NAME : (line + '.txt');
      return line;
    }
    return DEFAULT_FILE_NAME;
  }
}
