



<div class="arm-sim-box invisible-scrollbar" id="editors-grid">
  <h2 id="compiled-code-heading">Machine Code</h2>
  <h2 id="input-code-heading">
    <div>Source Code</div>
    <label class="arrow" id="upload" title="open a file with source code">
      <input type="file" id="input-file" (change)="uploadFile()">
      <mat-icon>file_upload</mat-icon>
    </label>
  
    <div class="arrow" (click)='downloadFile()' title="save source code"><mat-icon>file_download</mat-icon></div>
  </h2>


  <div id="cmCompiledCode">
    <ngx-codemirror
      class="CodeMirror"
      [options]="{
        readOnly: true,
        height: 'auto',
        firstLineNumber: 0,
        gutters: ['CodeMirror-addresses', 'CodeMirror-breakpoints']
      }"
      #codeMirrorCompiledCode
      id="codeMirrorCompiledCode"
      ></ngx-codemirror>
  </div>
  
  <div id="cmInputCode">
    <ngx-codemirror
      class="CodeMirror"
      [options]="{
        lineNumbers: true,
        height: 'auto',
        firstLineNumber: 0,
        keyMap: 'sublime'
      }"
      #codeMirrorInputCode
      id="codeMirrorInputCode"
    ></ngx-codemirror>
  </div>
</div>

<div class="flex-row sticky front">
  <button id="compile" class="" (click)="onClickCompile()" title="compile your code to start simulation">compile</button>
</div>
