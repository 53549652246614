import { Component, OnInit, Input } from '@angular/core';
import Register from '../../../../Backend/src/Register';

@Component({
  selector: 'app-registers',
  templateUrl: './registers.component.html',
  styleUrls: ['./registers.component.css']
})
export class RegistersComponent implements OnInit {

  @Input() registers: Register[];

  base: number = 16;
  hideZeros: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  setBase(base: number): void {
    this.base = base;
  }

  setHideZeros(hide: boolean): void {
    this.hideZeros = hide;
  }

}
