<h3>
  {{ memoryRegionString.name }}
</h3>

<div class="memory-region">

  <div>
    <div class="arm-sim-left-column addresses">
      <div *ngFor="let address of addresses"> {{ toAddress(address) }} </div>
    </div>
    <div class="arm-sim-left-column dots">⋮</div>
  </div>

  <div>
    <div [ngClass]="{
        'memory-region-values-grid': true,
        'bytes-per-row-8': bytesPerRow === 8,
        'bytes-per-row-4': bytesPerRow === 4,
        'bytes-per-row-2': bytesPerRow === 2
        }">
      <div class="memory-region-values" *ngFor="let value of memoryRegionString.values">{{ value }}</div>
    </div>
    <div class="dots">⋮</div>
  </div>

</div>

