import { CodemirrorComponent } from '@ctrl/ngx-codemirror';

const COMMA: string = '(\\s*,\\s*)';
const SPACE: string = '(\\s+)';
const REGISTER: string = '(a[0-7]|xr|xt[0-7]|ip[01]|xs[0-7]|gp|sp|fp|lr|xzr|x30|x31|x[0-2]?[0-9])';
const IMMEDIATE: string = '(0x[0-9a-f]+|0b[01]+|-?[0-9]+)';
export const REGEXP_IMMEDIATE: RegExp = new RegExp(IMMEDIATE, 'i');
const SHIFT_AMOUNT: string = '(lsl0|lsl16|lsl32|lsl48)';
const LABEL: string = '([a-z_][a-z0-9_]*)';
const LABEL_NON_CAPTURING: string = LABEL.slice(0, 1) + '?:' + LABEL.slice(1);
const LABEL_WITH_COLON: string = '^(\\s*)(' + LABEL_NON_CAPTURING + '(?:\\s*)(?::))?(\\s*)';

const R_OPCODES: string = '(add|adds|sub|subs|mul|sdiv|and|ands|orr|eor|orn|lslv|lsrv|rorv)';
const I_OPCODES: string = '(addi|addis|subi|subis|andi|andis|orri|eori|lsl|lsr|asr|ror)';
const D_OPCODES: string = '(ldur|ldurw|ldurh|ldurb|stur|sturw|sturh|sturb)';
const IW_OPCODES: string = '(movz|movk)';
const B_OPCODES: string = '(bl|b|b\\.eq|b\\.ne|b\\.lt|b\\.le|b\\.gt|b\\.ge)';
const CB_OPCODES: string = '(cbz|cbnz)';
const ONE_REG_OPCODES: string = '(br)';
const TWO_REG_OPCODES: string = '(not|mov|cmp)';
const LI_LA_OPCODES: string = '(la|li)';
const SINGLE_KEYWORDS: string = '(nop|svc|rfe|eret)';
const SPECIAL_REGISTER: string = '(elr|cause|status)';


const DOT_ALIGN_SPACE: string = '(\\.align|\\.space)';
const CHAR: string = `('.'|'\\\\n'|'\\\\t'|'\\\\0')`;
const STRING: string = '(\"(?:\\\\"|[^\"])*?\")';
const DOT_BYTE_WORD_DOUBLEWORD: string = '(\\.byte|\\.word|\\.doubleword)';
const IMMEDIATE_LIST: string = '((?:(?:\\s+)(?:0x[0-9a-f]+|0b[01]+|-?[0-9]+))+)';

export const REGEXP_TEXT: RegExp = new RegExp('^(\\s*)(\\.text)', 'im');
export const REGEXP_TEXT_IMMEDIATE: RegExp = new RegExp('^(\\s*)(\\.text)' + SPACE + IMMEDIATE, 'im');

export const REGEXP_DATA: RegExp = new RegExp('^(\\s*)(\\.data)', 'im');
export const REGEXP_DATA_IMMEDIATE: RegExp = new RegExp('^(\\s*)(\\.data)' + SPACE + IMMEDIATE, 'im');

export const REGEXP_KERNEL_TEXT: RegExp = new RegExp('^(\\s*)(\\.kernel_text)', 'im');

export const REGEXP_KERNEL_DATA: RegExp = new RegExp('^(\\s*)(\\.kernel_data)', 'im');

export const REGEXP_SEGMENT_START: RegExp = new RegExp( '(' + REGEXP_TEXT_IMMEDIATE.source
                                                      + '|' + REGEXP_TEXT.source
                                                      + '|' + REGEXP_DATA_IMMEDIATE.source
                                                      + '|' + REGEXP_DATA.source
                                                      + '|' + REGEXP_KERNEL_TEXT.source
                                                      + '|' + REGEXP_KERNEL_DATA.source + ')', 'i');

export const REGEXP_DOT_GLOBL: RegExp = new RegExp(LABEL_WITH_COLON + '(\\.globl)' + SPACE + LABEL, 'i');

export const REGEXP_DOT_REGISTER: RegExp = new RegExp(LABEL_WITH_COLON
                                                    + '(\\.register)'
                                                    + SPACE
                                                    + REGISTER
                                                    + COMMA
                                                    + '(#)'
                                                    + IMMEDIATE
                                                    , 'i');

export const REGEXP_DOT_ALIGN_SPACE: RegExp = new RegExp(LABEL_WITH_COLON
                                                        + DOT_ALIGN_SPACE
                                                        + SPACE
                                                        + IMMEDIATE
                                                        , 'i');

export const REGEXP_DOT_BYTE_WORD_DOUBLEWORD: RegExp = new RegExp(LABEL_WITH_COLON
                                                                + DOT_BYTE_WORD_DOUBLEWORD
                                                                + IMMEDIATE_LIST
                                                                , 'i');

export const REGEXP_DOT_BYTE_WORD_DOUBLEWORD_LABEL: RegExp = new RegExp(LABEL_WITH_COLON
                                                                  + DOT_BYTE_WORD_DOUBLEWORD
                                                                  + SPACE
                                                                  + LABEL
                                                                  , 'i');

export const REGEXP_DOT_BYTE_WORD_DOUBLEWORD_CHAR: RegExp = new RegExp(LABEL_WITH_COLON
                                                                    + DOT_BYTE_WORD_DOUBLEWORD
                                                                    + SPACE
                                                                    + CHAR
                                                                    , 'i');

export const REGEXP_DOT_ASCIIZ: RegExp = new RegExp(LABEL_WITH_COLON
                                                    + '(\\.asciiz)'
                                                    + SPACE
                                                    + STRING
                                                    , 'i');

export const REGEXP_DOT_EXTERN: RegExp = new RegExp(LABEL_WITH_COLON
                                                    + '(\\.extern)'
                                                    + SPACE
                                                    + LABEL
                                                    + SPACE
                                                    + IMMEDIATE
                                                    , 'i');


export const REGEXP_COMMENT = /\/\/.*/;
const REGEXP_COMMENT_WITH_SPACE = RegExp('\\s*' + REGEXP_COMMENT.source);
// export const REGEXP_MULTILINE_COMMENT = /\/\*(?:[^\*]*\n?)*\*\//;
export const REGEXP_EMPTY_LINE = /^(\s)*$/;

export const REGEXP_SINGLE_KEYWORD_INSTRUCTION: RegExp = new RegExp(LABEL_WITH_COLON + SINGLE_KEYWORDS, 'i');
export const REGEXP_CMP_IMMEDIATE: RegExp = new RegExp(LABEL_WITH_COLON + '(cmp)' + SPACE + REGISTER + COMMA + '(#)' + IMMEDIATE, 'i');

export const REGEXP_LABEL: RegExp = new RegExp(LABEL + '(?:\\s*)' + '(:)', 'i');
export const REGEXP_LABEL_WITHOUT_INSTRUCTION: RegExp = new RegExp(REGEXP_LABEL.source + REGEXP_COMMENT_WITH_SPACE.source + '$');

export const REGEXP_R_TYPE_INSTRUCTION: RegExp = new RegExp(LABEL_WITH_COLON
                                                            + R_OPCODES
                                                            + SPACE
                                                            + REGISTER
                                                            + COMMA
                                                            + REGISTER
                                                            + COMMA
                                                            + REGISTER
                                                            , 'i');

export const REGEXP_I_TYPE_INSTRUCTION: RegExp = new RegExp(LABEL_WITH_COLON
                                                            + I_OPCODES
                                                            + SPACE
                                                            + REGISTER
                                                            + COMMA
                                                            + REGISTER
                                                            + COMMA
                                                            + '(#)'
                                                            + IMMEDIATE
                                                            , 'i');

//#region D-Instructions
export const REGEXP_D_TYPE_INSTRUCTION_REG: RegExp = new RegExp(LABEL_WITH_COLON
                                                                + D_OPCODES
                                                                + SPACE
                                                                + REGISTER
                                                                + COMMA
                                                                + '(\\[\\s*)'
                                                                + REGISTER
                                                                + '(\\s*\\])'
                                                                , 'i');

export const REGEXP_D_TYPE_INSTRUCTION_IMM: RegExp = new RegExp(LABEL_WITH_COLON
                                                                + D_OPCODES
                                                                + SPACE
                                                                + REGISTER
                                                                + COMMA
                                                                + '(#)'
                                                                + IMMEDIATE
                                                                , 'i');

export const REGEXP_D_TYPE_INSTRUCTION_REG_IMM: RegExp = new RegExp(LABEL_WITH_COLON
                                                            + D_OPCODES
                                                            + SPACE
                                                            + REGISTER
                                                            + COMMA
                                                            + '(\\[\\s*)'
                                                            + REGISTER
                                                            + COMMA
                                                            + '(#)'
                                                            + IMMEDIATE
                                                            + '(\\s*\\])'
                                                            , 'i');

export const REGEXP_D_TYPE_INSTRUCTION_SYM: RegExp = new RegExp(LABEL_WITH_COLON
                                                              + D_OPCODES
                                                              + SPACE
                                                              + REGISTER
                                                              + COMMA
                                                              + LABEL
                                                              , 'i');

export const REGEXP_D_TYPE_INSTRUCTION_SYM_IMM: RegExp = new RegExp(LABEL_WITH_COLON
                                                                  + D_OPCODES
                                                                  + SPACE
                                                                  + REGISTER
                                                                  + COMMA
                                                                  + LABEL
                                                                  + '(\\s*\\+\\s*)'
                                                                  + '(#)'
                                                                  + IMMEDIATE
                                                                  , 'i');

export const REGEXP_D_TYPE_INSTRUCTION_SYM_REG_IMM: RegExp = new RegExp(LABEL_WITH_COLON
                                                                      + D_OPCODES
                                                                      + SPACE
                                                                      + REGISTER
                                                                      + COMMA
                                                                      + LABEL
                                                                      + '(\\s*\\+\\s*)'
                                                                      + '(\\[\\s*)'
                                                                      + REGISTER
                                                                      + COMMA
                                                                      + '(#)'
                                                                      + IMMEDIATE
                                                                      + '(\\s*\\])'
                                                                      , 'i');
//#endregion

export const REGEXP_IW_TYPE_INSTRUCTION: RegExp = new RegExp(LABEL_WITH_COLON
                                                            + IW_OPCODES
                                                            + SPACE
                                                            + REGISTER
                                                            + COMMA
                                                            + '(#)'
                                                            + IMMEDIATE
                                                            + COMMA
                                                            + SHIFT_AMOUNT
                                                            , 'i');

export const REGEXP_B_TYPE_INSTRUCTION_IMMEDIATE: RegExp = new RegExp(LABEL_WITH_COLON
                                                                    + B_OPCODES
                                                                    + SPACE
                                                                    + IMMEDIATE
                                                                    , 'i');

export const REGEXP_B_TYPE_INSTRUCTION_LABEL: RegExp = new RegExp(LABEL_WITH_COLON
                                                                + B_OPCODES
                                                                + SPACE
                                                                + LABEL
                                                                , 'i');

export const REGEXP_CB_TYPE_INSTRUCTION_IMMEDIATE: RegExp = new RegExp(LABEL_WITH_COLON
                                                                    + CB_OPCODES
                                                                    + SPACE
                                                                    + REGISTER
                                                                    + COMMA
                                                                    + IMMEDIATE
                                                                    , 'i');

export const REGEXP_CB_TYPE_INSTRUCTION_LABEL: RegExp = new RegExp(LABEL_WITH_COLON
                                                                + CB_OPCODES
                                                                + SPACE
                                                                + REGISTER
                                                                + COMMA
                                                                + LABEL
                                                                , 'i');

export const REGEXP_2_REG_TYPE_INSTRUCTION: RegExp = new RegExp(LABEL_WITH_COLON
                                                                + TWO_REG_OPCODES
                                                                + SPACE
                                                                + REGISTER
                                                                + COMMA
                                                                + REGISTER
                                                                , 'i');

export const REGEXP_1_REG_TYPE_INSTRUCTION: RegExp = new RegExp(LABEL_WITH_COLON
                                                                  + ONE_REG_OPCODES
                                                                  + SPACE
                                                                  + REGISTER
                                                                  , 'i');

export const REGEXP_MSR_INSTRUCTION: RegExp = new RegExp(LABEL_WITH_COLON
                                                                  + '(msr)'
                                                                  + SPACE
                                                                  + SPECIAL_REGISTER
                                                                  + COMMA
                                                                  + REGISTER
                                                                  , 'i');

export const REGEXP_MRS_INSTRUCTION: RegExp = new RegExp(LABEL_WITH_COLON
                                                                  + '(mrs)'
                                                                  + SPACE
                                                                  + REGISTER
                                                                  + COMMA
                                                                  + SPECIAL_REGISTER
                                                                  , 'i');

export const REGEXP_LI_LA_INSTRUCTION_IMMEDIATE: RegExp = new RegExp(LABEL_WITH_COLON
                                                                    + LI_LA_OPCODES
                                                                    + SPACE
                                                                    + REGISTER
                                                                    + COMMA
                                                                    + '(#)'
                                                                    + IMMEDIATE
                                                                , 'i');

export const REGEXP_LI_LA_INSTRUCTION_LABEL: RegExp = new RegExp(LABEL_WITH_COLON
                                                                + LI_LA_OPCODES
                                                                + SPACE
                                                                + REGISTER
                                                                + COMMA
                                                                + LABEL
                                                                , 'i');

export function initMiniArmMode(cmComponent: CodemirrorComponent): void {
    cmComponent.codeMirrorGlobal.defineSimpleMode('mini-ARM', {
        // The start state contains the rules that are intially used
        start: [
          // Comments //
          { regex: REGEXP_COMMENT_WITH_SPACE, token: 'comment' },
          // { regex: /\/\*/, token: 'comment', push: 'comment' },

          // .data
          { regex: REGEXP_DATA_IMMEDIATE,
            token: ['null', 'def', 'null', 'number'], next: 'data' },
          { regex: REGEXP_DATA,
            token: ['null', 'def'], next: 'data' },

          // .kernel_data
          { regex: REGEXP_KERNEL_DATA,
            token: ['null', 'def'], next: 'data' },

          // .text
          { regex: REGEXP_TEXT_IMMEDIATE,
            token: ['null', 'def', 'null', 'number'], next: 'text_and_kernel' },
          { regex: REGEXP_TEXT,
            token: ['null', 'def'], next: 'text_and_kernel' },

          // .kernel_text
          { regex: REGEXP_KERNEL_TEXT,
            token: ['null', 'def'], next: 'text_and_kernel' },

          // errors
          { regex: /.*/, token: 'error'}

        ],

        // The .text and .interrupt_handler Segment state
        text_and_kernel: [
            // Comments //
            { regex: REGEXP_COMMENT_WITH_SPACE, token: 'comment' },
            // { regex: /\/\*/, token: 'comment', push: 'comment' },

            // Directives
            // .text
            { regex: REGEXP_TEXT_IMMEDIATE,
              token: ['null', 'def', 'null', 'number'], next: 'text_and_kernel' },
            { regex: REGEXP_TEXT,
              token: ['null', 'def'], next: 'text_and_kernel' },
            // .kernel_text
            { regex: REGEXP_KERNEL_TEXT,
              token: ['null', 'def'], next: 'text_and_kernel' },
            // .data
            { regex: REGEXP_DATA_IMMEDIATE,
              token: ['null', 'def', 'null', 'number'], next: 'data' },
            { regex: REGEXP_DATA,
              token: ['null', 'def'], next: 'data' },
            // .kernel_data
          { regex: REGEXP_KERNEL_DATA,
            token: ['null', 'def'], next: 'data' },
            // .globl
            { regex: REGEXP_DOT_GLOBL,
              token: ['null', 'tag', 'null', 'meta', 'null', 'tag'],
              sol: true},
            // .globl
            { regex: REGEXP_DOT_REGISTER,
              token: ['null', 'tag', 'null', 'meta', 'null', 'variable', 'punctuation', 'number', 'number'],
              sol: true},

            // Label
            // { regex: REGEXP_LABEL_WITHOUT_INSTRUCTION, token: 'error' }, // Label without instruction
            // { regex: REGEXP_LABEL, token: 'tag' }, // Label

            // R-Type instructions
            { regex: REGEXP_R_TYPE_INSTRUCTION,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable', 'punctuation', 'variable', 'punctuation', 'variable'],
              sol: true},

            // I-Type instructions
            { regex: REGEXP_I_TYPE_INSTRUCTION,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable', 'punctuation', 'variable', 'punctuation', 'number', 'number'],
              sol: true},

            // D-Type instructions
            { regex: REGEXP_D_TYPE_INSTRUCTION_REG,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable', 'punctuation', 'bracket', 'variable', 'bracket'],
              sol: true},
            { regex: REGEXP_D_TYPE_INSTRUCTION_IMM,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable', 'punctuation', 'number', 'number'],
              sol: true},
            { regex: REGEXP_D_TYPE_INSTRUCTION_REG_IMM,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable', 'punctuation', 'bracket', 'variable', 'punctuation', 'number', 'number', 'bracket'],
              sol: true},
            { regex: REGEXP_D_TYPE_INSTRUCTION_SYM_REG_IMM,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable', 'punctuation', 'tag', 'punctuation', 'bracket', 'variable', 'punctuation', 'number', 'number', 'bracket'],
              sol: true},
            { regex: REGEXP_D_TYPE_INSTRUCTION_SYM_IMM,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable', 'punctuation', 'tag', 'punctuation', 'number', 'number'],
              sol: true},
            { regex: REGEXP_D_TYPE_INSTRUCTION_SYM,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable', 'punctuation', 'tag'],
              sol: true},

            // IW-Type instructions
            { regex: REGEXP_IW_TYPE_INSTRUCTION,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable', 'punctuation', 'number', 'number', 'punctuation', 'number'],
              sol: true},

            // B-Type instructions
            { regex: REGEXP_B_TYPE_INSTRUCTION_IMMEDIATE,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'number'],
              sol: true},
            { regex: REGEXP_B_TYPE_INSTRUCTION_LABEL,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'tag'],
              sol: true},

            // CB-Type instructions
            { regex: REGEXP_CB_TYPE_INSTRUCTION_IMMEDIATE,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable', 'punctuation', 'number'],
              sol: true},
            { regex: REGEXP_CB_TYPE_INSTRUCTION_LABEL,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable', 'punctuation', 'tag'],
              sol: true},

            // 2-Register-Type instructions
            { regex: REGEXP_2_REG_TYPE_INSTRUCTION,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable', 'punctuation', 'variable'],
              sol: true},

            // 1-Register-Type instructions
            { regex: REGEXP_1_REG_TYPE_INSTRUCTION,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable'],
              sol: true},

            // MSR MRS instructions
            { regex: REGEXP_MSR_INSTRUCTION,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable', 'punctuation', 'variable'],
              sol: true},
            { regex: REGEXP_MRS_INSTRUCTION,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable', 'punctuation', 'variable'],
              sol: true},

            // CMP with immediate
            { regex: REGEXP_CMP_IMMEDIATE,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable', 'punctuation', 'number', 'number'],
              sol: true},

            // LI LA instructions
            { regex: REGEXP_LI_LA_INSTRUCTION_IMMEDIATE,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable', 'punctuation', 'number', 'number'],
              sol: true},
            { regex: REGEXP_LI_LA_INSTRUCTION_LABEL,
              token: ['null', 'tag', 'null', 'keyword', 'null', 'variable', 'punctuation', 'tag'],
              sol: true},

            // NOP instructions
            { regex: REGEXP_SINGLE_KEYWORD_INSTRUCTION,
              token: ['null', 'tag', 'null', 'keyword'] ,
              sol: true},

            // errors
            { regex: /.*/, token: 'error'}

        ],

        // The multi-line comment state.
        // comment: [
        //     {
        //         regex: /.*?\*\//,
        //         token: 'comment',
        //         pop: true
        //     },
        //     {
        //         regex: /.*/,
        //         token: 'comment'
        //     }
        // ],

        // The .data segment state.
        data: [
            // Comments //
            { regex: REGEXP_COMMENT_WITH_SPACE, token: 'comment' },
            // { regex: /\/\*/, token: 'comment', push: 'comment' },

            // Direktives
            // .text
            { regex: REGEXP_TEXT_IMMEDIATE,
              token: ['null', 'def', 'null', 'number'], next: 'text_and_kernel' },
            { regex: REGEXP_TEXT,
              token: ['null', 'def'], next: 'text_and_kernel' },
            // .kernel_text
            { regex: REGEXP_KERNEL_TEXT,
              token: ['null', 'def'], next: 'text_and_kernel' },
            // .data
            { regex: REGEXP_DATA_IMMEDIATE,
              token: ['null', 'def', 'null', 'number'], next: 'data' },
            { regex: REGEXP_DATA,
              token: ['null', 'def'], next: 'data' },
            // .kernel_data
            { regex: REGEXP_KERNEL_DATA,
              token: ['null', 'def'], next: 'data' },
            // .align .space
            { regex: REGEXP_DOT_ALIGN_SPACE,
              token: ['null', 'tag', 'null', 'meta', 'null', 'number'],
              sol: true},
            // .byte .word .doubleword
            { regex: REGEXP_DOT_BYTE_WORD_DOUBLEWORD,
              token: ['null', 'tag', 'null', 'meta', 'number'],
              sol: true},
            { regex: REGEXP_DOT_BYTE_WORD_DOUBLEWORD_LABEL,
              token: ['null', 'tag', 'null', 'meta', 'null', 'tag'],
              sol: true},
            { regex: REGEXP_DOT_BYTE_WORD_DOUBLEWORD_CHAR,
              token: ['null', 'tag', 'null', 'meta', 'null', 'number'],
              sol: true},
            // .asciiz
            { regex: REGEXP_DOT_ASCIIZ,
              token: ['null', 'tag', 'null', 'meta', 'null', 'number'],
              sol: true},
            // .extern
            { regex: REGEXP_DOT_EXTERN,
              token: ['null', 'tag', 'null', 'meta', 'null', 'tag', 'null', 'number'],
              sol: true},
            // .globl
            { regex: REGEXP_DOT_GLOBL,
              token: ['null', 'tag', 'null', 'meta', 'null', 'tag'],
              sol: true},

            // Label
            // { regex: REGEXP_LABEL_WITHOUT_INSTRUCTION, token: 'error' }, // Label without instruction
            // { regex: REGEXP_LABEL, token: 'tag' }, // Label

            // errors
            { regex: /.*/, token: 'error'}

        ],
        // ===================================================================

        // The meta property contains global information about the mode. It
        // can contain properties like lineComment, which are supported by
        // all modes, and also directives like dontIndentStates, which are
        // specific to simple modes.
        meta: {
            dontIndentStates: ['comment'],
            lineComment: '//'
        }
    });
}
