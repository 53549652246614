import MemoryRegionInterface from "./MemoryRegionInterface";
import IoDeviceInterface from "./IoDeviceInterface";

export default class MemoryRegionIoDeviceHandler implements MemoryRegionInterface {
    private _lastAddress: number;
    private _startAddress: number;

    private _ioDevicesToStartAddress: Map<IoDeviceInterface, number> = new Map();

    get startAddress(): number {
        return this._startAddress;
    }

    get lastAddress(): number {
        return this._lastAddress;
    }

    constructor(ioDevices: IoDeviceInterface[], startAddress: number) {
        this._startAddress = startAddress;

        let nextStartAddress: number = startAddress;
        ioDevices.forEach((ioDevice): void => {
            this._ioDevicesToStartAddress.set(ioDevice, nextStartAddress);
            nextStartAddress += ioDevice.sizeInByte;
        });
        this._lastAddress = nextStartAddress - 1;
    }

    load(address: number, byteAmount: number, littleEndian: boolean): bigint {
        let result: bigint = BigInt(0);

        for (let i: number = 0; i < byteAmount; i++) {
            const ioDevice: IoDeviceInterface = this._addressToIoDevice(address + i);
            const offset: number = address + i - this._ioDevicesToStartAddress.get(ioDevice);
            result += BigInt(ioDevice.loadByte(offset)) << BigInt(8 * (byteAmount - i - 1));
        }

        return result;
    }

    store(address: number, value: bigint, byteAmount: number, littleEndian: boolean): void {
        const listOfBytes: bigint[] = this._splitBytes(value, byteAmount, littleEndian);

        listOfBytes.forEach((byteValue, index): void => {
            const ioDevice: IoDeviceInterface = this._addressToIoDevice(address + index);
            const offset: number = address + index - this._ioDevicesToStartAddress.get(ioDevice);
            ioDevice.storeByte(offset, byteValue);
        });
    }

    toString(base: number, hideZeros: boolean): string[] {
        let result: string[] = [];
        this._ioDevicesToStartAddress.forEach((_, ioDevice): void => {
            result = result.concat(ioDevice.toString(base, hideZeros));
        });
        return result;
    }

    private _splitBytes(value: bigint, byteAmount: number, littleEndian: boolean): bigint[] {
        const result: bigint[] = [];
        for (let i: number = byteAmount; i > 0; i--) {
            value = BigInt.asUintN(8 * i, value);
            result.push(value >> BigInt((i-1)*8));
        }

        return littleEndian ? result.reverse() : result;
    }

    private _addressToIoDevice(address: number): IoDeviceInterface {
        let result: IoDeviceInterface;
        this._ioDevicesToStartAddress.forEach((ioDeviceStartAddress, ioDevice): void => {
            if (ioDeviceStartAddress <= address && address < (ioDeviceStartAddress + ioDevice.sizeInByte)) {
                result = ioDevice;
            }
        });

        if (!result) {
            throw new Error("No IO device at " + address);
        }
        return result;
    }
}