import { REGISTER_SIZE_IN_BYTE, CONSOLE_STEP_AMOUNT } from './IoDeviceConsoleInput';
import IoDeviceInterface from './IoDeviceInterface';
import { valueToString } from './GlobalFunction';

export default class IoDeviceConsoleOutput implements IoDeviceInterface {
    sizeInByte: number = 2 * REGISTER_SIZE_IN_BYTE;

    private _output: string = "--- Mini-ARM-Simulator ---\n\n";
    private _stepsTillPrint: number = -1;

    private _transmitterReady: boolean = true; // Readonly. True, when ready to receive next Char. False, when buisy outputting previous char.
    private _transmitterData: bigint = BigInt(0); // 8-bit representing current char to print or was printed last respectively.

    get output(): string {
        return this._output;
    }

    print(value: string): void {
        this._output += value;
    }

    step(): void {
        if (this._stepsTillPrint > 0) {
            this._stepsTillPrint--;
        } else if (this._stepsTillPrint === 0) {
            this._printCurrentChar();
            this._stepsTillPrint--;
        }
    }

    loadByte(offset: number): bigint {
        if (offset === REGISTER_SIZE_IN_BYTE - 1) {
            return BigInt.asUintN(8, BigInt(this._transmitterReady ? 1 : 0));
        } else if (offset === 2 * REGISTER_SIZE_IN_BYTE - 1) {
            return BigInt.asUintN(8, this._transmitterData);
        }
        return BigInt(0);
    }

    storeByte(offset: number, value: bigint): void {
        if (offset === 2 * REGISTER_SIZE_IN_BYTE - 1) {
            this._transmitterData = BigInt.asUintN(8, value);
            this._stepsTillPrint = CONSOLE_STEP_AMOUNT;
            this._transmitterReady = false;
        }
    }

    toString(base: number, hideZeros: boolean): string[] {
        const result: string[] = [];

        for (let i: number = 0; i < REGISTER_SIZE_IN_BYTE - 1; i++) {
            result.push(valueToString(0, base, 1, hideZeros));
        }
        result.push(valueToString(this._transmitterReady ? 1 : 0, base, 1, hideZeros));

        for (let i: number = 0; i < REGISTER_SIZE_IN_BYTE - 1; i++) {
            result.push(valueToString(0, base, 1, hideZeros));
        }
        result.push(valueToString(this._transmitterData, base, 1, hideZeros));

        return result;
    }


    private _printCurrentChar(): void {
        this._output += String.fromCharCode(Number(this._transmitterData));
        this._transmitterReady = true;
    }
}