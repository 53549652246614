import { INSTRUCTION_LOOKUP_TABLE } from './instruction_lookup_table';
import { CompileError } from './CompileError';
import { DEFAULT_INTERRUPT_HANDLER } from './DefaultInterruptHandler';
import { RegisterName } from './registerName_enum';
import Memory, { DEFAULT_TEXT_START_ADDRESS, DEFAULT_OS_START_ADDRESS, DEFAULT_GLOBAL_DATA_START_ADDRESS, DEFAULT_HEAP_SIZE, DEFAULT_STACK_SIZE, STACK_LAST_ADDRESS } from './Memory';
import Processor from "./Processor";
import * as regexp from './../../frontend-arm-simulator/src/cm-mode-MiniARM';
import CompilerSegmentText from "./CompilerSegmentText";
import CompilerSegmentInterface from "./CompilerSegmentInterface";
import CompilerSegmentData from './CompilerSegmentData';
import IoDeviceInterface from './IoDeviceInterface';
import IoDeviceConsoleInput from './IoDeviceConsoleInput';
import IoDeviceConsoleOutput from './IoDeviceConsoleOutput';

const REGEXP_LINE_LAYOUTS: RegExp[] =  [regexp.REGEXP_DATA_IMMEDIATE,
                                        regexp.REGEXP_DATA,
                                        regexp.REGEXP_TEXT_IMMEDIATE,
                                        regexp.REGEXP_TEXT,
                                        regexp.REGEXP_DOT_ALIGN_SPACE,
                                        regexp.REGEXP_DOT_BYTE_WORD_DOUBLEWORD,
                                        regexp.REGEXP_DOT_BYTE_WORD_DOUBLEWORD_LABEL,
                                        regexp.REGEXP_DOT_BYTE_WORD_DOUBLEWORD_CHAR,
                                        regexp.REGEXP_DOT_ASCIIZ,
                                        regexp.REGEXP_DOT_REGISTER,
                                        regexp.REGEXP_DOT_GLOBL,
                                        regexp.REGEXP_DOT_EXTERN,

                                        regexp.REGEXP_R_TYPE_INSTRUCTION,
                                        regexp.REGEXP_I_TYPE_INSTRUCTION,
                                        regexp.REGEXP_D_TYPE_INSTRUCTION_REG,
                                        regexp.REGEXP_D_TYPE_INSTRUCTION_IMM,
                                        regexp.REGEXP_D_TYPE_INSTRUCTION_REG_IMM,
                                        regexp.REGEXP_D_TYPE_INSTRUCTION_SYM_REG_IMM,
                                        regexp.REGEXP_D_TYPE_INSTRUCTION_SYM_IMM,
                                        regexp.REGEXP_D_TYPE_INSTRUCTION_SYM,
                                        regexp.REGEXP_IW_TYPE_INSTRUCTION,
                                        regexp.REGEXP_B_TYPE_INSTRUCTION_LABEL,
                                        regexp.REGEXP_B_TYPE_INSTRUCTION_IMMEDIATE,
                                        regexp.REGEXP_CB_TYPE_INSTRUCTION_LABEL,
                                        regexp.REGEXP_CB_TYPE_INSTRUCTION_IMMEDIATE,
                                        regexp.REGEXP_SINGLE_KEYWORD_INSTRUCTION,
                                        regexp.REGEXP_2_REG_TYPE_INSTRUCTION,
                                        regexp.REGEXP_1_REG_TYPE_INSTRUCTION,
                                        regexp.REGEXP_CMP_IMMEDIATE,
                                        regexp.REGEXP_LI_LA_INSTRUCTION_LABEL,
                                        regexp.REGEXP_LI_LA_INSTRUCTION_IMMEDIATE,
                                        regexp.REGEXP_MSR_INSTRUCTION,
                                        regexp.REGEXP_MRS_INSTRUCTION,
                                        regexp.REGEXP_KERNEL_TEXT,
                                        regexp.REGEXP_KERNEL_DATA
                                        ];

export default class Compiler {
    private _flagCompiled: boolean = false;

    private _heapSize: number;
    private _stackSize: number;
    private _littleEndian: boolean;

    private _completeCode: string;
    private _splittedLines: string[];
    private _currentLine: string;
    private _indexPhase1: number = 0;

    private _currentLineInEditor: number = 0;
    private _editorLinesToInsertEmptyLines: number[] = [];

    private _currentSegment: CompilerSegmentInterface;
    private _allSegments: CompilerSegmentInterface[] = [];

    private _textSegment: CompilerSegmentText;
    private _dataSegment: CompilerSegmentData;
    private _kernelTextSegment: CompilerSegmentText;
    private _kernelDataSegment: CompilerSegmentData;

    private _labelTable: Map<string, number> = new Map();

    private _memory: Memory;
    private _processor: Processor;
    private _consoleInput: IoDeviceConsoleInput = new IoDeviceConsoleInput();
    private _consoleOutput: IoDeviceConsoleOutput = new IoDeviceConsoleOutput();

    //#region getter setter

    get processor(): Processor {
        return this._processor;
    }

    get currentLineInEditor(): number {
        return this._currentLineInEditor;
    }

    get onErrorCurrentLineInEditor(): number {
        return this._currentLineInEditor - this._editorLinesToInsertEmptyLines.length;
    }

    get editorLinesToInsertEmptyLines(): number[] {
        return this._editorLinesToInsertEmptyLines;
    }

    //#endregion

    constructor(code: string, heapSize: number = DEFAULT_HEAP_SIZE, stackSize: number = DEFAULT_STACK_SIZE, littleEndian: boolean = false) {
        this._completeCode = code;
        this._heapSize = heapSize;
        this._stackSize = stackSize;
        this._littleEndian = littleEndian;
    }

    compile(): void {
        if (this._flagCompiled) {
            throw new Error("Compiler is only for one compilation. For recompiling use new instance.");
        }
        this._flagCompiled = true;

        // this._removeMultilineComments();
        this._addDefaultInterruptHandler();
        this._splitLines();

        this._phase1();

        this._collectLabels();

        this._phase2();

        this._initializeMemory();

        this._processor = new Processor(this._textSegment.instructions, this._kernelTextSegment.instructions, this._memory, this._textSegment.startAddress, this._consoleInput, this._consoleOutput);
        this._setInitialRegisterValues();

        return;
    }

    incrementCurrentLineInEditor(): void {
        this._currentLineInEditor += 1;
    }

    pushEditorLineToInsertEmptyLine(): void {
        if (this._lineIsEmpty(this._splittedLines[this._indexPhase1 + 1])) {
            this._indexPhase1++;
        } else {
            this._editorLinesToInsertEmptyLines.push(this._currentLineInEditor);
        }
    }

    // private _removeMultilineComments(): void {
    //     this._completeCode = this._completeCode.split(regexp.REGEXP_MULTILINE_COMMENT).join("");
    // }

    private _splitLines(): void {
        this._splittedLines = this._completeCode.split("\n");
    }

    private _phase1(): void {
        // let i: number = 0;
        while (this._indexPhase1 < this._splittedLines.length) {
            this._currentLine = this._splittedLines[this._indexPhase1];
            this._compileCurrentLine();
            this._indexPhase1++;
        }
    }

    private _compileCurrentLine(): void {
        const comment: string = this._seperateComment();

        if (this._lineIsEmpty(this._currentLine)) {
            this.incrementCurrentLineInEditor();
            return;
        }

        const tokens: RegExpMatchArray = this._tokenizeCurrentLine();

        if (this._isNewSegment(tokens)) {
            this._initializeNewSegment(tokens);
        } else {
            if (this._currentSegment) {
                this._currentSegment.compile(tokens, comment, this._currentLineInEditor);
            } else {
                throw new CompileError(this.onErrorCurrentLineInEditor, `Make sure to start a segment before this line by using .text, .data, .kernel_text or .kernel_data .`);
            }
        }
    }

    private _seperateComment(): string {
        let comment: string = "";
        const matchResult: RegExpMatchArray = this._currentLine.match(regexp.REGEXP_COMMENT);
        if (matchResult) {
            comment = matchResult[0];  // returns comment
            this._currentLine = this._currentLine.slice(0, matchResult.index)    // returns rest of line
        }
        this._currentLine = this._currentLine.trim(); // removes leading and trailing whitespaces
        return comment;
    }

    private _lineIsEmpty(lineStr: string): boolean {
        if (lineStr === undefined) return false;
        return lineStr.match(regexp.REGEXP_EMPTY_LINE) ? true : false;
    }

    private _tokenizeCurrentLine(): RegExpMatchArray {
        for (const lineLayout of REGEXP_LINE_LAYOUTS) {
            const matchResult: RegExpMatchArray = this._currentLine.match(lineLayout);
            if (matchResult) {
                const overhead: string = matchResult.input.replace(matchResult[0], '').trim();
                if (this._lineIsEmpty(overhead)){
                    return matchResult;
                } else {
                    throw new CompileError(this.onErrorCurrentLineInEditor, `"${overhead}" exceeds a well defined instruction. In case it is a new instruction place it in a new line, else write "//" in front to ignore it.`)
                }
            }
        }

        let additionalInfo: string;
        if (this._currentLine.replace(regexp.REGEXP_LABEL, '').trim()[0] === '.') {
            additionalInfo = `<br><br>The following directives are supported: <br>
            .text <br>
            .text 0x400000 <br>
            .register A0, #42 <br>
            .data <br>
            .data 0x10000000 <br>
            .align 2 <br>
            .space 1 <br>
            .byte 1 <br>
            .byte 'a' <br>
            .word 1 <br>
            .doubleword 1 <br>
            .asciiz "Hello World!" <br>
            .kernel_text <br>
            .kernel_data`;
        } else if (this._currentLine.match(regexp.REGEXP_LABEL_WITHOUT_INSTRUCTION)) {
            additionalInfo = `<br>Labels without instruction are not allowed. Please use a 'NOP' instruction with the label if you can't avoid it.`;
        } else {
            const example: string = this._getExampleToInstruction(this._currentLine);
            additionalInfo = example ? `<br> <br>Is this the kind of instruction you are looking for? <br>${example}` : '';
        }
        throw new CompileError(this.onErrorCurrentLineInEditor, `This does not match any correct instruction. ${additionalInfo}`);
    }

    private _isNewSegment(tokens: RegExpMatchArray): boolean {
        switch (tokens[2]) {
            case '.data':
            case '.text':
            case '.kernel_text':
            case '.kernel_data':
                return true;
        }
        return false;
    }

    private _initializeNewSegment(tokens: RegExpMatchArray): void {
        switch (tokens[2]) {
            case '.data':
                {
                    if (this._dataSegment) {
                        this._doubleSegment(tokens[2]);
                    }
                    const startAddress: number = (tokens[4] !== undefined) ? Number(tokens[4]) : DEFAULT_GLOBAL_DATA_START_ADDRESS;
                    this._throwOnInvalidSegmentAddress(startAddress);

                    this._dataSegment = new CompilerSegmentData(this);
                    this._currentSegment = this._dataSegment;
                    this._allSegments.push(this._currentSegment);

                    this._dataSegment.startAddress = startAddress;
                    this.incrementCurrentLineInEditor();
                    break;
                }
            case '.text':
                {
                    if (this._textSegment) {
                        this._doubleSegment(tokens[2]);
                    }
                    const textStartAddress: number = (tokens[4] !== undefined) ? Number(tokens[4]) : DEFAULT_TEXT_START_ADDRESS;
                    this._throwOnInvalidSegmentAddress(textStartAddress);
                    this._textSegment = new CompilerSegmentText(this, textStartAddress);
                    this._currentSegment = this._textSegment;
                    this._allSegments.push(this._currentSegment);
                    this.incrementCurrentLineInEditor();
                    break;
                }
            case '.kernel_text':
                {
                    if (this._kernelTextSegment) {
                        this._doubleSegment(tokens[2]);
                    }
                    const kernelTextStartAddress: number = DEFAULT_OS_START_ADDRESS;
                    this._kernelTextSegment = new CompilerSegmentText(this, kernelTextStartAddress);
                    this._currentSegment = this._kernelTextSegment;
                    this._allSegments.push(this._currentSegment);
                    this.incrementCurrentLineInEditor();
                    break;
                }
            case '.kernel_data':
                {
                    if (this._kernelDataSegment) {
                        this._doubleSegment(tokens[2]);
                    }
                    this._kernelDataSegment = new CompilerSegmentData(this);
                    this._currentSegment = this._kernelDataSegment;
                    this._allSegments.push(this._currentSegment);
                    this.incrementCurrentLineInEditor();
                    break;
                }
        }
    }

    private _doubleSegment(name: string): void {
        throw new CompileError(this.onErrorCurrentLineInEditor, name + " directive is not allowed to be used twice in simulation.");
    }

    private _throwOnInvalidSegmentAddress(address: number): void {
        if (address % 4 !== 0) {
            throw new CompileError(this.onErrorCurrentLineInEditor, `The address must be a multiple of 4.`);
        }
        if (address >= STACK_LAST_ADDRESS) {
            throw new CompileError(this.onErrorCurrentLineInEditor, `The segment must start before ${STACK_LAST_ADDRESS}.`);
        }
    }

    private _collectLabels(): void {
        this._setKernelDataSegmentStartAddress();
        this._allSegments.forEach((segment):void => {
            segment.getLabels().forEach(({label, address, onErrorLineInEditor}): void => {
                if (this._labelTable.has(label)) {
                    throw new CompileError(onErrorLineInEditor, 'Label "' + label + '" is not allowed to be used twice.');
                } else {
                    this._labelTable.set(label, address);
                }
            });
        });
    }

    private _setKernelDataSegmentStartAddress(): void {
        if (this._kernelDataSegment) {
            this._kernelDataSegment.startAddress = this._kernelTextSegment.startAddress + this._kernelTextSegment.sizeInByte;
        }
    }

    private _phase2(): void {
        this._allSegments.forEach((segment): void => {
            segment.phase2(this._labelTable);
        });
    }

    private _initializeMemory(): void {
        const ioDevices: IoDeviceInterface[] = [];
        ioDevices.push(this._consoleInput);
        ioDevices.push(this._consoleOutput);

        const osTextSize: number = this._kernelTextSegment.sizeInByte;
        const osDataSize: number = (this._kernelDataSegment ? this._kernelDataSegment.sizeInByte : 0);

        const globalDataStart: number = (this._dataSegment !== undefined) ? this._dataSegment.startAddress : DEFAULT_GLOBAL_DATA_START_ADDRESS;
        const globalDataSize: number = (this._dataSegment !== undefined) ? this._dataSegment.sizeInByte : 0;
        this._memory = new Memory(osTextSize, osDataSize, this._textSegment.startAddress, this._textSegment.sizeInByte, globalDataStart, globalDataSize, this._heapSize, this._stackSize, ioDevices, this._littleEndian);

        this._allSegments.forEach((segment: CompilerSegmentInterface):void => {
            segment.fillMemory(this._memory);
        });

        this._memory.finishedCompiling();
    }

    private _addDefaultInterruptHandler(): void {
        if (!this._completeCode.match(regexp.REGEXP_KERNEL_TEXT)) {
            this._completeCode += DEFAULT_INTERRUPT_HANDLER;
            // console.warn("Default interrupt Handler was added. This should only happen during testing.")
        }
    }

    private _setInitialRegisterValues(): void {
        this._textSegment.setInitialRegisterValues(this._processor.registers);
    }

    private _getExampleToInstruction(line: string): string {
        for (const instructionType of INSTRUCTION_LOOKUP_TABLE) {
            if (line.toUpperCase().match(instructionType.opcodeString)) {
                return instructionType.example;
            }
        }
        return '';
    }

    //#region static methods (used in CompilerSegment Interfaces)

    toImmediate(immediateString: string, bitAmount: number, signed: boolean=true): bigint {
        let tooBig: boolean = false;
        let result: bigint;
        if (immediateString[0] === '0' && (immediateString[1] === 'x' || immediateString[1] === 'b')) {
            tooBig = (BigInt.asUintN(bitAmount, BigInt(immediateString)) !== BigInt(immediateString));
            result = signed ? BigInt.asIntN(bitAmount, BigInt(immediateString)) : BigInt.asUintN(bitAmount, BigInt(immediateString));
        } else {
            result = BigInt(immediateString);
            tooBig = Compiler._outOfBounds(result, bitAmount, signed);
        }
        if (tooBig) {
            throw new CompileError(this.onErrorCurrentLineInEditor, `${immediateString} cannot be stored in a ${bitAmount}-bit ${(signed?"":"un")}signed integer. <br><br>
            The immediate has to be in [${signed ? Compiler._underBoundOf(bitAmount) : 0} .. ${signed ? Compiler._upperBoundOf(bitAmount) : Compiler._upperBoundOf(bitAmount+1)}]`);
            // or respectively [${(signed ? Compiler._underBoundOf(bitAmount) : 0).toString(16)} ... ${(signed ? Compiler._upperBoundOf(bitAmount) : Compiler._upperBoundOf(bitAmount+1)).toString(16)}]`);
        }

        return result;
    }

    private static _outOfBounds(value: bigint, bitAmount: number, signed: boolean): boolean {
        if (signed) {
            return (value < this._underBoundOf(bitAmount) || this._upperBoundOf(bitAmount) < value);
        } else {
            return (value < BigInt(0) || this._upperBoundOf(bitAmount+1) < value);
        }
    }

    private static _upperBoundOf(bitAmount: number): number {
        return (2 ** (bitAmount - 1)) - 1;
    }

    private static _underBoundOf(bitAmount: number): number {
        return -(2 ** (bitAmount - 1));
    }

    static toRegister(registerString: string): RegisterName {
        registerString = registerString.toUpperCase();
        if (registerString.match(/(x30|x31|x[0-2]?[0-9])/i)) {
            return Number(registerString.slice(1));
        }
        return RegisterName[registerString];
    }

    static seperateLabel(str: string): string {
        let label: string = "";
        if (str) {
            const matchResult: RegExpMatchArray = str.match(regexp.REGEXP_LABEL);
            if (matchResult) {
                label = matchResult[1]; // returns label
            }
        }
        return label;
    }

    //#endregion
}