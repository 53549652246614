import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MemoryRegionToStringResultInterface } from '../../../../Backend/src/MemoryRegionToStringResultInterface';
import { toAddress } from '../global-functions';

@Component({
  selector: 'app-memory-region',
  templateUrl: './memory-region.component.html',
  styleUrls: ['./memory-region.component.css']
})
export class MemoryRegionComponent implements OnInit, OnChanges {

  @Input() memoryRegionString: MemoryRegionToStringResultInterface;
  @Input() bytesPerRow: number;

  addresses: number[];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.addresses = Array(Math.ceil(this.memoryRegionString.values.length / this.bytesPerRow)).fill(1).map((_, i) => i * this.bytesPerRow + this.memoryRegionString.startAddress);

  }

  toAddress(address: number): string {
    return toAddress(address);
  }

}
