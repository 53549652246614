import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-alu-flags',
  templateUrl: './alu-flags.component.html',
  styleUrls: ['./alu-flags.component.css']
})
export class AluFlagsComponent implements OnInit {

  @Input() flagC: boolean;
  @Input() flagN: boolean;
  @Input() flagV: boolean;
  @Input() flagZ: boolean;

  constructor() { }

  ngOnInit() {
  }

}
