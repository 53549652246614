import { Format } from './format_enum';
import { Opcode } from './opcode_enum';

export interface InstructionLookup {
    opcode: Opcode,
    opcodeString: string,
    format: Format,
    opcodeValue: number,
    example: string
}

export const opcodeToInstructionLookup = (opcodeString: string): InstructionLookup => {
    return INSTRUCTION_LOOKUP_TABLE.filter((i: InstructionLookup):boolean => i.opcodeString === opcodeString)[0];
};

export const INSTRUCTION_LOOKUP_TABLE: InstructionLookup[] =
    [
        { opcode: Opcode.ADDIS, opcodeString: "ADDIS",  format: Format.I_FORMAT,    opcodeValue: 708    , example: `ADDIS A0, A1, #1`},
        { opcode: Opcode.ADDI,  opcodeString: "ADDI",   format: Format.I_FORMAT,    opcodeValue: 580    , example: `ADDI A0, A1, #1`},
        { opcode: Opcode.ADDS,  opcodeString: "ADDS",   format: Format.R_FORMAT,    opcodeValue: 1368   , example: `ADDS A0, A1, A2`},
        { opcode: Opcode.ADD,   opcodeString: "ADD",    format: Format.R_FORMAT,    opcodeValue: 1112   , example: `ADD A0, A1, A2`},
        { opcode: Opcode.SUBIS, opcodeString: "SUBIS",  format: Format.I_FORMAT,    opcodeValue: 964    , example: `SUBIS A0, A1, #1`},
        { opcode: Opcode.SUBI,  opcodeString: "SUBI",   format: Format.I_FORMAT,    opcodeValue: 836    , example: `SUBI A0, A1, #1`},
        { opcode: Opcode.SUBS,  opcodeString: "SUBS",   format: Format.R_FORMAT,    opcodeValue: 1880   , example: `SUBS A0, A1, A2`},
        { opcode: Opcode.SUB,   opcodeString: "SUB",    format: Format.R_FORMAT,    opcodeValue: 1624   , example: `SUB A0, A1, A2`},
        { opcode: Opcode.MUL,   opcodeString: "MUL",    format: Format.R_FORMAT,    opcodeValue: 1240   , example: `MUL A0, A1, A2`},
        { opcode: Opcode.SDIV,  opcodeString: "SDIV",   format: Format.R_FORMAT,    opcodeValue: 1238   , example: `SDIV A0, A1, A2`},
        { opcode: Opcode.ANDIS, opcodeString: "ANDIS",  format: Format.I_FORMAT,    opcodeValue: 968    , example: `ANDIS A0, A1, #1`},
        { opcode: Opcode.ANDI,  opcodeString: "ANDI",   format: Format.I_FORMAT,    opcodeValue: 584    , example: `ANDI A0, A1, #1`},
        { opcode: Opcode.ANDS,  opcodeString: "ANDS",   format: Format.R_FORMAT,    opcodeValue: 1872   , example: `ANDS  A0, A1, A2`},
        { opcode: Opcode.AND,   opcodeString: "AND",    format: Format.R_FORMAT,    opcodeValue: 1104   , example: `AND A0, A1, A2`},
        { opcode: Opcode.ORRI,  opcodeString: "ORRI",   format: Format.I_FORMAT,    opcodeValue: 712    , example: `ORRI A0, A1, #1`},
        { opcode: Opcode.ORR,   opcodeString: "ORR",    format: Format.R_FORMAT,    opcodeValue: 1360   , example: `ORR A0, A1, A2`},
        { opcode: Opcode.EORI,  opcodeString: "EORI",   format: Format.I_FORMAT,    opcodeValue: 840    , example: `EORI A0, A1, #1`},
        { opcode: Opcode.EOR,   opcodeString: "EOR",    format: Format.R_FORMAT,    opcodeValue: 1616   , example: `EOR A0, A1, A2`},
        { opcode: Opcode.ORN,   opcodeString: "ORN",    format: Format.R_FORMAT,    opcodeValue: 1361   , example: `ORN A0, A1, A2`},
        { opcode: Opcode.NOT,   opcodeString: "NOT",    format: Format.PSEUDO_INSTR,opcodeValue: 0      , example: `NOT  A0, A1`},
        { opcode: Opcode.LSLV,  opcodeString: "LSLV",   format: Format.R_FORMAT,    opcodeValue: 1238   , example: `LSLV A0, A1, A2`},
        { opcode: Opcode.LSRV,  opcodeString: "LSRV",   format: Format.R_FORMAT,    opcodeValue: 1239   , example: `LSRV A0, A1, A2`},
        { opcode: Opcode.RORV,  opcodeString: "RORV",   format: Format.R_FORMAT,    opcodeValue: 1246   , example: `RORV A0, A1, A2`},
        { opcode: Opcode.LSL,   opcodeString: "LSL",    format: Format.I_FORMAT,    opcodeValue: 844    , example: `LSL A0, A1, #1`},
        { opcode: Opcode.LSR,   opcodeString: "LSR",    format: Format.I_FORMAT,    opcodeValue: 845    , example: `LSR A0, A1, #1`},
        { opcode: Opcode.ASR,   opcodeString: "ASR",    format: Format.I_FORMAT,    opcodeValue: 589    , example: `ASR A0, A1, #1`},
        { opcode: Opcode.ROR,   opcodeString: "ROR",    format: Format.I_FORMAT,    opcodeValue: 591    , example: `ROR A0, A1, #1`},
        { opcode: Opcode.LDURW, opcodeString: "LDURW",  format: Format.D_FORMAT,    opcodeValue: 1476   , example: `LDURW A0, [A1] or <br>LDURW A0, #1 or <br>LDURW A0, [A1, #1] or <br>LDURW A0, label + [A1, #1] or <br>LDURW A0, label + #1 or <br>LDURW A0, label`},
        { opcode: Opcode.LDURH, opcodeString: "LDURH",  format: Format.D_FORMAT,    opcodeValue: 962    , example: `LDURH A0, [A1] or <br>LDURH A0, #1 or <br>LDURH A0, [A1, #1] or <br>LDURH A0, label + [A1, #1] or <br>LDURH A0, label + #1 or <br>LDURH A0, label`},
        { opcode: Opcode.LDURB, opcodeString: "LDURB",  format: Format.D_FORMAT,    opcodeValue: 450    , example: `LDURB A0, [A1] or <br>LDURB A0, #1 or <br>LDURB A0, [A1, #1] or <br>LDURB A0, label + [A1, #1] or <br>LDURB A0, label + #1 or <br>LDURB A0, label`},
        { opcode: Opcode.LDUR,  opcodeString: "LDUR",   format: Format.D_FORMAT,    opcodeValue: 1986   , example: `LDUR A0, [A1] or <br>LDUR A0, #1 or <br>LDUR A0, [A1, #1] or <br>LDUR A0, label + [A1, #1] or <br>LDUR A0, label + #1 or <br>LDUR A0, label`},
        { opcode: Opcode.STURW, opcodeString: "STURW",  format: Format.D_FORMAT,    opcodeValue: 1472   , example: `STURW A0, [A1] or <br>STURW A0, #1 or <br>STURW A0, [A1, #1] or <br>STURW A0, label + [A1, #1] or <br>STURW A0, label + #1 or <br>STURW A0, label`},
        { opcode: Opcode.STURH, opcodeString: "STURH",  format: Format.D_FORMAT,    opcodeValue: 960    , example: `STURH A0, [A1] or <br>STURH A0, #1 or <br>STURH A0, [A1, #1] or <br>STURH A0, label + [A1, #1] or <br>STURH A0, label + #1 or <br>STURH A0, label`},
        { opcode: Opcode.STURB, opcodeString: "STURB",  format: Format.D_FORMAT,    opcodeValue: 448    , example: `STURB A0, [A1] or <br>STURB A0, #1 or <br>STURB A0, [A1, #1] or <br>STURB A0, label + [A1, #1] or <br>STURB A0, label + #1 or <br>STURB A0, label`},
        { opcode: Opcode.STUR,  opcodeString: "STUR",   format: Format.D_FORMAT,    opcodeValue: 1984   , example: `STUR A0, [A1] or <br>STUR A0, #1 or <br>STUR A0, [A1, #1] or <br>STUR A0, label + [A1, #1] or <br>STUR A0, label + #1 or <br>STUR A0, label`},
        { opcode: Opcode.MOVZ,  opcodeString: "MOVZ",   format: Format.IW_FORMAT,   opcodeValue: 421    , example: `MOVZ A0, #1, LSL0 or <br>MOVZ A0, #1, LSL16 or <br>MOVZ A0, #1, LSL32 or <br>MOVZ A0, #1, LSL48`},
        { opcode: Opcode.MOVK,  opcodeString: "MOVK",   format: Format.IW_FORMAT,   opcodeValue: 485    , example: `MOVK A0, #1, LSL0 or <br>MOVK A0, #1, LSL16 or <br>MOVK A0, #1, LSL32 or <br>MOVK A0, #1, LSL48`},
        { opcode: Opcode.MOV,   opcodeString: "MOV",    format: Format.PSEUDO_INSTR,opcodeValue: 0      , example: `MOV A0, A1`},
        { opcode: Opcode.LI,    opcodeString: "LI",     format: Format.PSEUDO_INSTR,opcodeValue: 0      , example: `LI A0, #1 or <br>LI A0, label`},
        { opcode: Opcode.LA,    opcodeString: "LA",     format: Format.PSEUDO_INSTR,opcodeValue: 0      , example: `LA A0, #1 or <br>LA A0, label`},
        { opcode: Opcode.BR,    opcodeString: "BR",     format: Format.R_FORMAT,    opcodeValue: 1712   , example: `BR A0`},
        { opcode: Opcode.BL,    opcodeString: "BL",     format: Format.B_FORMAT,    opcodeValue: 37     , example: `BL 1 or <br>BL label`},
        { opcode: Opcode.CBZ,   opcodeString: "CBZ",    format: Format.CB_FORMAT,   opcodeValue: 180    , example: `CBZ A0, 1 or <br>CBZ A0, label`},
        { opcode: Opcode.CBNZ,  opcodeString: "CBNZ",   format: Format.CB_FORMAT,   opcodeValue: 181    , example: `CBNZ A0, 1 or <br>CBNZ A0, label`},
        { opcode: Opcode.CMP,   opcodeString: "CMP",    format: Format.PSEUDO_INSTR,opcodeValue: 0      , example: `CMP A0, A1 or <br>CMP A0, #1`},
        { opcode: Opcode.BEQ,   opcodeString: "B.EQ",   format: Format.CB_FORMAT,    opcodeValue: 84     , example: `B.EQ 1 or <br>B.EQ label`},
        { opcode: Opcode.BNE,   opcodeString: "B.NE",   format: Format.CB_FORMAT,    opcodeValue: 85     , example: `B.NE 1 or <br>B.NE label`},
        { opcode: Opcode.BLT,   opcodeString: "B.LT",   format: Format.CB_FORMAT,    opcodeValue: 86     , example: `B.LT 1 or <br>B.LT label`},
        { opcode: Opcode.BLE,   opcodeString: "B.LE",   format: Format.CB_FORMAT,    opcodeValue: 87     , example: `B.LE 1 or <br>B.LE label`},
        { opcode: Opcode.BGT,   opcodeString: "B.GT",   format: Format.CB_FORMAT,    opcodeValue: 88     , example: `B.GT 1 or <br>B.GT label`},
        { opcode: Opcode.BGE,   opcodeString: "B.GE",   format: Format.CB_FORMAT,    opcodeValue: 89     , example: `B.GE 1 or <br>B.GE label`},
        { opcode: Opcode.B,     opcodeString: "B",      format: Format.B_FORMAT,    opcodeValue: 5      , example: `B 1 or <br>B label`},
        { opcode: Opcode.SVC,   opcodeString: "SVC",    format: Format.R_FORMAT,    opcodeValue: 1696   , example: `SVC`},
        { opcode: Opcode.RFE,   opcodeString: "RFE",    format: Format.R_FORMAT,    opcodeValue: 1716   , example: `RFE`},
        { opcode: Opcode.ERET,  opcodeString: "ERET",   format: Format.R_FORMAT,    opcodeValue: 1717   , example: `ERET`},
        { opcode: Opcode.NOP,   opcodeString: "NOP",    format: Format.PSEUDO_INSTR,opcodeValue: 0      , example: `NOP`},
        { opcode: Opcode.MSR,   opcodeString: "MSR",    format: Format.R_FORMAT,    opcodeValue: 1801   , example: `MSR ELR, A0 or <br>MSR CAUSE, A0 or <br>MSR STATUS, A0`},
        { opcode: Opcode.MRS,   opcodeString: "MRS",    format: Format.R_FORMAT,    opcodeValue: 1800   , example: `MRS A0, ELR or <br>MRS A0, CAUSE or <br>MRS A0, STATUS`}
    ];