export enum Opcode {
    ADD = "ADD",
    ADDS = "ADDS",
    ADDI = "ADDI",
    ADDIS = "ADDIS",
    SUB = "SUB",
    SUBS = "SUBS",
    SUBI = "SUBI",
    SUBIS = "SUBIS",
    MUL = "MUL",
    SDIV = "SDIV",
    AND = "AND",
    ANDS = "ANDS",
    ANDI = "ANDI",
    ANDIS = "ANDIS",
    ORR = "ORR",
    ORRI = "ORRI",
    EOR = "EOR",
    EORI = "EORI",
    ORN = "ORN",
    NOT = "NOT",
    LSL = "LSL",
    LSR = "LSR",
    ASR = "ASR",
    ROR = "ROR",
    LSLV = "LSLV",
    LSRV = "LSRV",
    RORV = "RORV",
    LDUR = "LDUR",
    LDURW = "LDURW",
    LDURH = "LDURH",
    LDURB = "LDURB",
    STUR = "STUR",
    STURW = "STURW",
    STURH = "STURH",
    STURB = "STURB",
    MOV = "MOV",
    MOVZ = "MOVZ",
    MOVK = "MOVK",
    LI = "LI",
    LA = "LA",
    B = "B",
    BR = "BR",
    BL = "BL",
    CBZ = "CBZ",
    CBNZ = "CBNZ",
    CMP = "CMP",
    BEQ = "BEQ",
    BNE = "BNE",
    BLT = "BLT",
    BLE = "BLE",
    BGT = "BGT",
    BGE = "BGE",
    SVC = "SVC",
    RFE = "RFE",
    ERET = "ERET",
    NOP = "NOP",
    NONE = "NONE",
    MSR = "MSR",
    MRS = "MRS"
}