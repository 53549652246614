<div class="arm-sim-box console">
  
  <h2>Console</h2>
  
  
  <div id="console-output">{{ consoleOutput }}</div>
  
  <input [(ngModel)]="_consoleInput" class="invisible-scrollbar" id="console-input" title="input">
  
</div>
