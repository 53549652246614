import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.css']
})
export class ConsoleComponent implements OnInit {

  @Input() consoleOutput: string;

  @Input() consoleInput: string;
  @Output() consoleInputChange: EventEmitter<string> = new EventEmitter<string>();

  set _consoleInput(str: string) {
    this.consoleInput = str;
    this.consoleInputChange.emit(this.consoleInput);
  }

  get _consoleInput(): string {
    return this.consoleInput;
  }

  constructor() { }

  ngOnInit() {
  }

}
