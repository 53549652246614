<div class="arm-sim-box">
  <h2>Alu Flags</h2>

  <div id="alu-grid">
    <div class="arm-sim-left-column">Carry</div>
    <div class="alu-value">{{ flagC ? 1 : 0 }}</div>
    <div class="arm-sim-left-column">Negative</div>
    <div class="alu-value">{{ flagN ? 1 : 0 }}</div>
    <div class="arm-sim-left-column">Overflow</div>
    <div class="alu-value">{{ flagV ? 1 : 0 }}</div>
    <div class="arm-sim-left-column">Zero</div>
    <div class="alu-value">{{ flagZ ? 1 : 0 }}</div>
  </div>
</div>