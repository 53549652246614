import { Component, OnInit, Input, OnChanges } from '@angular/core';
import Memory from '../../../../Backend/src/Memory';
import { DEFAULT_HEAP_SIZE, DEFAULT_STACK_SIZE } from '../../../../Backend/src/Memory';
import { MemoryRegionToStringResultInterface } from '../../../../Backend/src/MemoryRegionToStringResultInterface';

@Component({
  selector: 'app-memory',
  templateUrl: './memory.component.html',
  styleUrls: ['./memory.component.css']
})
export class MemoryComponent implements OnInit, OnChanges {

  @Input() memory: Memory;
  memoryRegionsString: MemoryRegionToStringResultInterface[];

  private _heapSize: number = DEFAULT_HEAP_SIZE;
  private _stackSize: number = DEFAULT_STACK_SIZE;
  littleEndian: boolean = false;
  showRecompileMessage: boolean = false;

  base: number = 16;
  hideZeros: boolean = false;
  bytesPerRow: number = 8;

  //#region getter setter

  set heapSize(value: number) {
    this._heapSize = value;
    this.showRecompileMessage = true;
  }

  get heapSize(): number {
    return this._heapSize;
  }

  set stackSize(value: number) {
    this._stackSize = value;
    this.showRecompileMessage = true;
  }

  get stackSize(): number {
    return this._stackSize;
  }

  //#endregion

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(): void {
    if (this.memory) {
      this.memoryRegionsString = this.memory.toString(this.base, this.hideZeros);
    }
  }

  setBytesPerRow(bytesPerRow: number): void {
    this.bytesPerRow = bytesPerRow;
  }

  setBase(base: number): void {
    this.base = base;
    this.ngOnChanges();
  }

  setHideZeros(hide: boolean): void {
    this.hideZeros = hide;
    this.ngOnChanges();
  }

  setLittleEndian(littleEndian: boolean): void {
    this.littleEndian = littleEndian;
    this.showRecompileMessage = true;
  }

  scrollAwayPreCompileSettings(): void {
    document.getElementById('memory').scrollTo({
      top: 127,
      left: 0,
      behavior: 'smooth'
    });
  }

  hideAwayRecompileMessage(): void {
    this.showRecompileMessage = false;
  }

}
