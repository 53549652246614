export enum RegisterName {
    A0,
    A1,
    A2,
    A3,
    A4,
    A5,
    A6,
    A7,
    XR,
    XT0,
    XT1,
    XT2,
    XT3,
    XT4,
    XT5,
    XT6,
    IP0,
    IP1,
    XT7,
    XS0,
    XS1,
    XS2,
    XS3,
    XS4,
    XS5,
    XS6,
    XS7,
    GP,
    SP,
    FP,
    LR,
    XZR
};