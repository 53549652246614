import { MemoryComponent } from './memory/memory.component';
import { CodeComponent } from './code/code.component';
import { Component, ViewChild, AfterViewInit } from '@angular/core';
import SimulationStepHandler from './../../../Backend/src/SimulationStepHandler';
import Processor from '../../../Backend/src/Processor';
import { RegistersComponent } from './registers/registers.component';
import { toAddress } from './global-functions';
import { CompileError } from '../../../Backend/src/CompileError';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  @ViewChild(MemoryComponent) private memoryComponent: MemoryComponent;
  @ViewChild(CodeComponent) private codeComponent: CodeComponent;
  @ViewChild(RegistersComponent) private registerComponent: RegistersComponent; // del

  title: string = 'ARM-Simulator';

  simulationHandler: SimulationStepHandler;
  processor: Processor;

  parentTester: string = '@Input works as well! Awesome!';

  compile(code: string): void {
    const newSimulationHandler: SimulationStepHandler = new SimulationStepHandler();
    let emptyLinesToBeInsertedIntoEditor: number[];
    try {
      emptyLinesToBeInsertedIntoEditor = newSimulationHandler.compile(code, this.memoryComponent.heapSize, this.memoryComponent.stackSize, this.memoryComponent.littleEndian);
    } catch (error) {
      if (error instanceof CompileError) {
        const compileError: CompileError = error as CompileError;

        this.codeComponent.setError(compileError.lineInEditor, compileError.message);
        this._scrollToCodeLine(compileError.lineInEditor);
      } else {
        alert((error as Error).message);
        throw error;
      }
    }
    if (emptyLinesToBeInsertedIntoEditor) {
      this.simulationHandler = newSimulationHandler;
      this.processor = this.simulationHandler.processor;

      this.codeComponent.insertCompilationInformation(this.processor.textInstructions, this.processor.osInstructions, emptyLinesToBeInsertedIntoEditor);
      this.codeComponent.removeGreyedOut();
      this.codeComponent.pc = this.processor.pc;
      this.memoryComponent.ngOnChanges();
      this.memoryComponent.hideAwayRecompileMessage();
    }

    // this.memoryComponent.scrollAwayPreCompileSettings();
  }

  onClickSingleStep(): void {
    try {
      this.simulationHandler.executeSingleStep();
      this.memoryComponent.ngOnChanges();
    } catch (error) {
      this.memoryComponent.ngOnChanges();
      alert((error as Error).message);
      throw error;
    }
    this._scrollToCodeAddress(this.processor.pc);
  }

  onClickExecuteAll(): void {
    try {
      this.simulationHandler.executeAll();
      this.memoryComponent.ngOnChanges();
    } catch (error) {
      this.memoryComponent.ngOnChanges();
      alert((error as Error).message);
      throw error;
    }

    this._scrollToCodeAddress(this.processor.oldPc);
  }

  onClickToBreakpoint(): void {
    try {
      this.simulationHandler.executeToNextBreakpoint();
      this.memoryComponent.ngOnChanges();
    } catch (error) {
      this.memoryComponent.ngOnChanges();
      alert((error as Error).message);
      throw error;
    }
    this._scrollToCodeAddress(this.processor.pc);
  }

  setConsoleInput(str: string): void {
    this.processor.consoleInput = str;
    this.memoryComponent.ngOnChanges();
  }

  toAddress(address: number): string {
    return toAddress(address, 4);
  }

  reload(): void {
    location.reload();
  }

  private _scrollToCodeAddress(address: number): void {
    const line: number = this.codeComponent._addressToLine.get(address);
    this._scrollToCodeLine(line);
  }

  private _scrollToCodeLine(line: number): void {
    const height: number = 31.5 + line * 21.2 - document.getElementById('code').clientHeight / 2;
    document.getElementById('code').scrollTo({
      top: height,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngAfterViewInit(): void {
    this.codeComponent.onClickCompile();
  }
}
