<!-- pre compile settings -->
<div class="memory-pre-compile">
  
  <div class="size-setting">heap  size:</div>
  <div  class="size-setting">
    <input class="memory-number" type="number" [(ngModel)]="heapSize" min="0" >bytes
  </div>

  <div class="size-setting">stack size: </div>
  <div class="size-setting">
    <input class="memory-number" type="number" [(ngModel)]="stackSize" min="0" >bytes
  </div>

  <div class="size-setting">endian:</div>
  <!-- endian -->
  <div class="switch-toggle no-copy" id="endian" title="change endian">
  
    <div class="switch-toggle-rectangle" id="endian-rectangle"></div>
  
    <input id="memory-big" name="memory-endian" type="radio" checked="checked" />
    <label class="memory-endian-label" for="memory-big" (click)="setLittleEndian(false)" title="big endian">big</label>

    <input id="memory-little" name="memory-endian" type="radio" />
    <label class="memory-endian-label" for="memory-little" (click)="setLittleEndian(true)" title="little endian">little</label>
  
  </div>

</div>

<div class="message" *ngIf="showRecompileMessage">
  Changing heap size, stack size or endian does not influence the current simulation. Please recompile to apply changes.
</div>


<ng-container *ngIf="memory">

  <!-- buttons after compiling -->
  <div class="memory-buttons">

    <div class="button-row">

      <!-- base -->
      <div class="switch-toggle no-copy" id="base" title="change base">
      
        <div class="switch-toggle-rectangle" id="base-rectangle"></div>
    
        <input id="memory-base-2" name="memory-base" type="radio" />
        <label for="memory-base-2" (click)="setBase(2)" title="show values binary">2</label>
      
        <input id="memory-base-16" name="memory-base" type="radio" checked="checked" />
        <label for="memory-base-16" (click)="setBase(16)" title="show values hexadecimal">16</label>
      
      </div>

      <!-- zero representation -->
      <div class="switch-toggle no-copy" id="zero" title="change zero representation">
      
        <div class="switch-toggle-rectangle" id="zero-rectangle"></div>
    
        <input id="memory-hide" name="memory-zeros" type="radio" />
        <label for="memory-hide" (click)="setHideZeros(true)" title="hide zeros">.</label>
      
        <input id="memory-show" name="memory-zeros" type="radio" checked="checked" />
        <label for="memory-show" class="disabled" (click)="setHideZeros(false)" title="show zeros">0</label>
      
      </div>

    </div>

    <!-- bytes per row -->
    <div class="switch-toggle no-copy" id="columns" title="change bytes per row">
    
      <div class="switch-toggle-rectangle" id="columns-rectangle"></div>
  
      <input id="memory-col-2" name="memory-columns" type="radio" />
      <label for="memory-col-2" (click)="setBytesPerRow(2)" title="2 bytes per row">2</label>
  
      <input id="memory-col-4" name="memory-columns" type="radio" />
      <label for="memory-col-4" (click)="setBytesPerRow(4)" title="4 bytes per row">4</label>
    
      <input id="memory-col-8" name="memory-columns" type="radio" checked="checked" />
      <label for="memory-col-8" (click)="setBytesPerRow(8)" title="8 bytes per row">8</label>
    
    </div>

  </div>

  <!-- values -->
  <div class="arm-sim-box">
    <h2>Memory</h2>

    <ng-container *ngFor="let mr of memoryRegionsString">
      <app-memory-region [memoryRegionString]="mr" [bytesPerRow]="bytesPerRow"></app-memory-region>
    </ng-container>

  </div>

</ng-container>
