import { CompileError } from './CompileError';
import { ShiftAmount } from './shift_amount_enum';
import { RegisterName } from './registerName_enum';
import { Opcode } from './opcode_enum';
import { Format } from './format_enum';
import { InstructionLookup, opcodeToInstructionLookup } from './instruction_lookup_table';
import CompilerSegmentInterface from "./CompilerSegmentInterface";
import Instruction from "./Instruction";
import Compiler from "./Compiler";
import { REGEXP_IMMEDIATE, REGEXP_D_TYPE_INSTRUCTION_REG, REGEXP_D_TYPE_INSTRUCTION_IMM, REGEXP_D_TYPE_INSTRUCTION_REG_IMM, REGEXP_D_TYPE_INSTRUCTION_SYM, REGEXP_D_TYPE_INSTRUCTION_SYM_IMM, REGEXP_D_TYPE_INSTRUCTION_SYM_REG_IMM } from './../../frontend-arm-simulator/src/cm-mode-MiniARM';
import Memory from './Memory';
import Register from './Register';

const IMMEDIATE_BITS_AMOUNT: Map<Format, number> = new Map([
    [Format.B_FORMAT, 26],
    [Format.CB_FORMAT, 19],
    [Format.D_FORMAT, 11],
    [Format.IW_FORMAT, 16],
    [Format.I_FORMAT, 12],
    [Format.PSEUDO_INSTR, 64],
]);

export default class CompilerSegmentText implements CompilerSegmentInterface {
    private _compiler: Compiler;

    private _startAddress: number;
    private _instructions: Instruction[] = [];
    private _currentInstructionAddress: number;
    private _initialRegisterValues: Map<RegisterName, bigint> = new Map();
    private _labels: {label: string, address: number, onErrorLineInEditor: number}[] = [];


    get instructions(): Instruction[] {
        return this._instructions;
    }

    get sizeInByte(): number {
        return (this._instructions.length + (this._instructions.length % 2)) * 4;
    }

    get startAddress(): number {
        return this._startAddress;
    }

    constructor(compiler: Compiler, startAddress: number) {
        this._compiler = compiler;
        this._startAddress = startAddress;
        this._currentInstructionAddress = startAddress;
    }

    compile(tokens: RegExpMatchArray, comment: string): void {
        const label: string = Compiler.seperateLabel(tokens[2]);
        this._rememberLabel(label);

        if (tokens[4][0] === '.') {
            this._compileDirective(tokens);
        } else {
            this._compileInstruction(tokens, label, comment);
        }
    }

    fillMemory(memory: Memory): void {
        this._instructions.forEach((instruction): void => {
            memory.store(instruction.address, instruction.getAsBinary(), 4, true);
        });
    }

    getLabels(): {label: string, address: number, onErrorLineInEditor: number}[] {
        return this._labels;
    }

    phase2(labelTable: Map<string, number>): void {
        this._argumentLabelToImmediate(labelTable);
    }

    setInitialRegisterValues(registers: Register[]): void {
        this._initialRegisterValues.forEach((value, key):void => {
            registers[key].value = BigInt.asUintN(64, value);
        })
    }

    private _rememberLabel(label: string): void {
        if (label !== "") {
            const address: number = this._currentInstructionAddress;
            const onErrorLineInEditor: number = this._compiler.onErrorCurrentLineInEditor;
            this._labels.push({label, address, onErrorLineInEditor});
        }
    }

    private _compileDirective(tokens: RegExpMatchArray): void {
        switch (tokens[4]) {
            case '.register':
                {
                    const register: RegisterName = Compiler.toRegister(tokens[6]);
                    const value: bigint = this._compiler.toImmediate(tokens[9], 64, true);
                    this._initialRegisterValues.set(register, value);
                    break;
                }
            case '.globl':
                {
                    // does nothing
                    break;
                }
            case '.space':
            case '.align':
            case '.byte':
            case '.word':
            case '.doubleword':
            case '.asciiz':
            case '.extern':
                {
                    throw new CompileError(this._compiler.onErrorCurrentLineInEditor, `"${tokens[4]}" must be used in a .data segment.`);
                }
            default:
                {
                    throw new CompileError(this._compiler.onErrorCurrentLineInEditor, `Unknown directive "${tokens[4]}".`);
                }
        }
        this._compiler.incrementCurrentLineInEditor();
    }

    private _compileInstruction(tokens: RegExpMatchArray, label: string, comment: string): void {
        let opcodeString: string = tokens[4].toUpperCase();
        const entry: InstructionLookup = opcodeToInstructionLookup(opcodeString);
        let format: Format = entry.format;
        let opcode: Opcode = entry.opcode;
        let opcodeValue: number = entry.opcodeValue;

        switch (format) {
            case Format.R_FORMAT:
                {
                    if (opcode === "BR") {
                        const r1: number = Compiler.toRegister(tokens[6]);
                        const rd: number = 0;
                        const r2: number = 0;
                        this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, r1, r2, undefined, undefined, undefined, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
                    } else if (opcode === "SVC") {
                        const rd: number = 0;
                        const r1: number = 0;
                        const r2: number = 0;
                        this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, r1, r2, undefined, undefined, undefined, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
                    } else if (opcode === "MSR") {
                        const rd: number = Compiler.toRegister(tokens[8]);
                        const r1: number = 0;
                        const r2: number = this._toSpecialRegister(tokens[6].toUpperCase());
                        this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, r1, r2, undefined, undefined, undefined, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
                    } else if (opcode === "MRS") {
                        const rd: number = Compiler.toRegister(tokens[6]);
                        const r1: number = 0;
                        const r2: number = this._toSpecialRegister(tokens[8].toUpperCase());
                        this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, r1, r2, undefined, undefined, undefined, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
                    } else if (opcode === "RFE") {
                        const rd: number = 0;
                        const r1: number = 0;
                        const r2: number = 0;
                        this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, r1, r2, undefined, undefined, undefined, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
                    } else if (opcode === "ERET") {
                        const rd: number = 0;
                        const r1: number = 0;
                        const r2: number = 0;
                        this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, r1, r2, undefined, undefined, undefined, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
                    } else { // usual case
                        const rd: number = Compiler.toRegister(tokens[6]);
                        const r1: number = Compiler.toRegister(tokens[8]);
                        const r2: number = Compiler.toRegister(tokens[10]);
                        this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, r1, r2, undefined, undefined, undefined, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
                    }
                    break;
                }

            case Format.I_FORMAT:
                {
                    const rd: number = Compiler.toRegister(tokens[6]);
                    const r1: number = Compiler.toRegister(tokens[8]);
                    const immediate: bigint = this._compiler.toImmediate(tokens[11], IMMEDIATE_BITS_AMOUNT.get(format));
                    this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, r1, undefined, immediate, undefined, undefined, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
                    break;
                }

            case Format.D_FORMAT:
                {
                    let rd: number;
                    let r1: number;
                    let immediate: bigint;
                    if (tokens.input.match(REGEXP_D_TYPE_INSTRUCTION_REG)) {
                        rd = Compiler.toRegister(tokens[6]);
                        r1 = Compiler.toRegister(tokens[9]);
                        immediate = BigInt(0);
                    } else if (tokens.input.match(REGEXP_D_TYPE_INSTRUCTION_IMM)) {
                        this._insertMovzkInstructions(label, comment, RegisterName.XR, undefined, tokens[9], 3, true, false);
                        rd = Compiler.toRegister(tokens[6]);
                        r1 = RegisterName.XR;
                        immediate = BigInt(0);
                    } else if (tokens.input.match(REGEXP_D_TYPE_INSTRUCTION_REG_IMM)) {
                        rd = Compiler.toRegister(tokens[6]);
                        r1 = Compiler.toRegister(tokens[9]);
                        immediate = this._compiler.toImmediate(tokens[12], IMMEDIATE_BITS_AMOUNT.get(format));
                    } else if (tokens.input.match(REGEXP_D_TYPE_INSTRUCTION_SYM_REG_IMM)) {
                        this._insertMovzkInstructions(label, comment, RegisterName.XR, tokens[8], "0x000100020003", 3, true, false);

                        // ADD XR, XR, register
                        this._insertAdd(label, comment, Compiler.toRegister(tokens[11]));

                        rd = Compiler.toRegister(tokens[6]);
                        r1 = RegisterName.XR;
                        immediate = this._compiler.toImmediate(tokens[14], IMMEDIATE_BITS_AMOUNT.get(format));
                    } else if (tokens.input.match(REGEXP_D_TYPE_INSTRUCTION_SYM_IMM)) {
                        this._insertMovzkInstructions(label, comment, RegisterName.XR, tokens[8], "0x000100020003", 3, true, false);
                        rd = Compiler.toRegister(tokens[6]);
                        r1 = RegisterName.XR;
                        immediate = this._compiler.toImmediate(tokens[11], IMMEDIATE_BITS_AMOUNT.get(format));
                    } else if (tokens.input.match(REGEXP_D_TYPE_INSTRUCTION_SYM)) {
                        this._insertMovzkInstructions(label, comment, RegisterName.XR, tokens[8], "0x000100020003", 3, true, false);
                        rd = Compiler.toRegister(tokens[6]);
                        r1 = RegisterName.XR;
                        immediate = BigInt(0);
                    } else {
                        throw new Error("Unknown load store instruction format.");
                    }
                    this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, r1, undefined, immediate, undefined, undefined, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
                    break;
                }

            case Format.IW_FORMAT:
                {
                    const rd: number = Compiler.toRegister(tokens[6]);
                    const immediate: bigint = this._compiler.toImmediate(tokens[9], IMMEDIATE_BITS_AMOUNT.get(format), false);
                    const shiftAmount: ShiftAmount = this._toShiftAmount(tokens[11]);
                    this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, undefined, undefined, immediate, shiftAmount, undefined, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
                    break;
                }

            case Format.CB_FORMAT:
                {
                    if (opcodeString[0] === 'C') { // case CBZ and CBNZ instructions
                        const rd: number = Compiler.toRegister(tokens[6]);
                        let immediate: bigint;
                        let argumentLabel: string;
                        if (this._isImmediate(tokens[8])) {
                            immediate = this._compiler.toImmediate(tokens[8], IMMEDIATE_BITS_AMOUNT.get(format));
                        } else {
                            argumentLabel = tokens[8];
                        }
                        this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, undefined, undefined, immediate, undefined, argumentLabel, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
                        break;
                    }
                    // ATTENTION: else (not CBZ or CBNZ) runs into next case (B_Format), since B.xx assembly is B_Format
                }

            case Format.B_FORMAT: // ATTENTION: do not reorder! B.xx instruction run into here from case CB_Format!
                {
                    let immediate: bigint;
                    let argumentLabel: string;
                    if (this._isImmediate(tokens[6])) {
                        immediate = this._compiler.toImmediate(tokens[6], IMMEDIATE_BITS_AMOUNT.get(format));
                    } else {
                        argumentLabel = tokens[6];
                    }
                    const rd: RegisterName = 0; // because of B.xx instructions, so: rd === (00000)_2
                    this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, undefined, undefined, immediate, undefined, argumentLabel, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
                    break;
                }

            case Format.PSEUDO_INSTR:
                switch (opcode) {
                    case "NOT":
                        {
                            format = Format.R_FORMAT;
                            opcode = Opcode.ORN;
                            opcodeString = "ORN";
                            opcodeValue = opcodeToInstructionLookup(opcodeString).opcodeValue;
                            const rd: number = Compiler.toRegister(tokens[6]);
                            const r1: number = Compiler.toRegister(tokens[8]);
                            const r2: number = r1;
                            this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, r1, r2, undefined, undefined, undefined, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
                            break;
                        }
                    case "MOV":
                        {
                            format = Format.I_FORMAT;
                            opcode = Opcode.ADDI;
                            opcodeString = "ADDI";
                            opcodeValue = opcodeToInstructionLookup(opcodeString).opcodeValue;
                            const rd: number = Compiler.toRegister(tokens[6]);
                            const r1: number = Compiler.toRegister(tokens[8]);
                            const immediate: bigint = BigInt(0);
                            this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, r1, undefined, immediate, undefined, undefined, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
                            break;
                        }
                    case "LI":
                    case "LA":
                        {
                            let immediateString: string = "0x111111111111";
                            let argumentLabel: string;
                            if (tokens[8] === '#') {
                                immediateString = tokens[9];
                            } else {
                                argumentLabel = tokens[8];
                            }
                            const rd: number = Compiler.toRegister(tokens[6]);
                            this._insertMovzkInstructions(label, comment, rd, argumentLabel, immediateString, (opcode==="LI" ? 4 : 3), false, opcode==="LI");
                            break;
                        }
                    case "CMP":
                        {
                            let r2: number;
                            let immediate: bigint;

                            if (tokens[8] === '#') {
                                format = Format.I_FORMAT;
                                opcode = Opcode.SUBIS;
                                opcodeString = "SUBIS";
                                immediate = this._compiler.toImmediate(tokens[9], IMMEDIATE_BITS_AMOUNT.get(format));
                            }
                            else {
                                format = Format.R_FORMAT;
                                opcode = Opcode.SUBS;
                                opcodeString = "SUBS";
                                r2 = Compiler.toRegister(tokens[8]);

                            }
                            opcodeValue = opcodeToInstructionLookup(opcodeString).opcodeValue;
                            const rd: number = RegisterName.XZR;
                            const r1: number = Compiler.toRegister(tokens[6]);
                            this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, r1, r2, immediate, undefined, undefined, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
                            break;
                        }
                    case "NOP":
                        {
                            format = Format.R_FORMAT;
                            opcode = Opcode.ADD;
                            opcodeString = "ADD";
                            opcodeValue = opcodeToInstructionLookup(opcodeString).opcodeValue;
                            const rd: number = RegisterName.XZR;
                            const r1: number = RegisterName.XZR;
                            const r2: number = RegisterName.XZR;
                            this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, r1, r2, undefined, undefined, undefined, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
                            break;
                        }
                    case "SVC":
                        {
                            format = Format.R_FORMAT;
                            opcodeValue = opcodeToInstructionLookup(opcodeString).opcodeValue;
                            const rd: number = 0;
                            const r1: number = 0;
                            const r2: number = 0;
                            this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, r1, r2, undefined, undefined, undefined, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
                            break;
                        }
                }

        }
        this._incrementAddress();
    }

    private _toSpecialRegister(registerString: string): number {
        if (registerString === "STATUS") return 12;
        if (registerString === "CAUSE") return 13;
        if (registerString === "ELR") return 14;
        throw new Error("Unknown special register " + registerString);
    }

    private _toShiftAmount(shiftAmountString: string): ShiftAmount {
        return ShiftAmount[shiftAmountString.toUpperCase()];
    }

    private _isImmediate(literal: string): boolean {
        const regExpImmediate: RegExp = new RegExp('^' + REGEXP_IMMEDIATE.source + '$', 'i');
        return regExpImmediate.test(literal);
    }

    private _insertMovzkInstructions(label: string, comment: string, rd: RegisterName, argumentLabel: string, immediate: string = "0", amountOfMovzkInstructions: number, incrementAddressAfterLastInstruction: boolean, signed: boolean): void {
        const immediate64: bigint = BigInt.asUintN(64, this._compiler.toImmediate(immediate, amountOfMovzkInstructions * 16, signed));
        const immediates16: bigint[] = this._toMovAmounts(immediate64, amountOfMovzkInstructions);

        const format: Format = Format.IW_FORMAT;
        let opcode: Opcode = Opcode.MOVZ;
        let opcodeString: string = "MOVZ";
        let opcodeValue: number = opcodeToInstructionLookup(opcodeString).opcodeValue;

        let i: number = immediates16.length - 1;
        while (immediates16[i] === BigInt(0) && i > 0) {
            i--;
        }
        let shiftAmount: ShiftAmount = i * 16;
        this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, undefined, undefined, immediates16[i], shiftAmount, argumentLabel, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));

        i--;
        opcode = Opcode.MOVK;
        opcodeString = "MOVK";
        opcodeValue = opcodeToInstructionLookup(opcodeString).opcodeValue;
        while (i >= 0) {
            if (immediates16[i] !== BigInt(0)) {
                shiftAmount = i * 16;
                this._incrementAddress();
                this._compiler.pushEditorLineToInsertEmptyLine();
                this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, undefined, undefined, immediates16[i], shiftAmount, argumentLabel, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
            }
            i--;
        }

        if (incrementAddressAfterLastInstruction) {
            this._incrementAddress();
            this._compiler.pushEditorLineToInsertEmptyLine();
        }
    }

    private _insertAdd(label: string, comment: string, r2: RegisterName): void {
        const format: Format = Format.R_FORMAT;
        const opcode: Opcode = Opcode.ADD;
        const opcodeString: string = "ADD";
        const opcodeValue: number = opcodeToInstructionLookup(opcodeString).opcodeValue;
        const rd: number = RegisterName.XR;
        const r1: number = RegisterName.XR;
        this._instructions.push(new Instruction(this._currentInstructionAddress, label, format, opcode, opcodeString, opcodeValue, comment, rd, r1, r2, undefined, undefined, undefined, this._compiler.currentLineInEditor, this._compiler.onErrorCurrentLineInEditor));
        this._incrementAddress();
        this._compiler.pushEditorLineToInsertEmptyLine();
    }

    private _incrementAddress(): void {
        this._currentInstructionAddress += 4;
        this._compiler.incrementCurrentLineInEditor();
    }

    private _toMovAmounts(immediate64: bigint, amountOfMovzkInstructions: number = 4): bigint[] {
        const values: bigint[] = [];
        for (let i: number = amountOfMovzkInstructions - 1; i >= 0; i--) {
            const imm16: bigint = BigInt.asUintN(16, BigInt.asIntN((i+1)*16, immediate64) >> BigInt(i*16));
            values[i] = imm16;
        }
        return values;
    }

    private _argumentLabelToImmediate(labelTable: Map<string, number>): void {
        this._instructions.forEach((instruction):void => {
            if (instruction.argumentLabel !== undefined) {
                if (labelTable.has(instruction.argumentLabel)) {
                    if (instruction.opcode === "MOVK" || instruction.opcode === "MOVZ") {
                        const immediate64: bigint = BigInt(labelTable.get(instruction.argumentLabel));
                        const immediates16: bigint[] = this._toMovAmounts(immediate64);
                        instruction.immediate = immediates16[instruction.shiftAmount / 16]
                    } else {
                        const immediate64: bigint = BigInt((labelTable.get(instruction.argumentLabel) - instruction.address)/4);
                        instruction.immediate = immediate64;
                    }
                } else {
                    throw new CompileError(instruction.onErrorLineInEditor, `The label ${instruction.argumentLabel} is not defined.`);
                }
            }
        });
    }
}