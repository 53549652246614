import { ReadonlyError } from './ReadonlyError';
import { OsReadError } from './OsReadError';
import { Opcode } from './opcode_enum';
import CoProcessor0 from './CoProcessor0';
import { RegisterName } from './registerName_enum';
import Instruction from "./Instruction";
import Register from './Register';
import Alu from './Alu';
import Memory, { STACK_LAST_ADDRESS, DEFAULT_OS_START_ADDRESS } from './Memory';
// import ArmConsole from './ArmConsole';
import IoDeviceConsoleInput from './IoDeviceConsoleInput';
import IoDeviceConsoleOutput from './IoDeviceConsoleOutput';
import { valueToString } from './GlobalFunction';

const REGISTER_AMOUNT: number = 32;

export default class Processor {
    private _textInstructions: Instruction[];
    private _osInstructions: Instruction[];
    // tslint:disable-next-line: variable-name
    private __pc: number;
    private _oldPc: number = -1;
    private _registers: Register[] = [];
    private _memory: Memory;
    private _coprocessor0: CoProcessor0 = new CoProcessor0(this);
    private _alu: Alu = new Alu(this._coprocessor0);
    private _ioInput: IoDeviceConsoleInput;
    private _ioOutput: IoDeviceConsoleOutput;

    private _textStartAddress: number;

    //#region getter setter

    get textInstructions(): Instruction[] {
        return this._textInstructions;
    }

    get osInstructions(): Instruction[] {
        return this._osInstructions;
    }

    get pc(): number {
        return this._pc;
    }

    private get _pc(): number {
        return this.__pc;
    }

    private set _pc(value: number) {
        this._oldPc = this.__pc;
        this.__pc = value;
    }

    get oldPc(): number {
        return this._oldPc;
    }

    get registers(): Register[] {
        return this._registers;
    }

    get flagC(): boolean {
        return this._coprocessor0.flagC;
    }

    get flagN(): boolean {
        return this._coprocessor0.flagN;
    }

    get flagV(): boolean {
        return this._coprocessor0.flagV;
    }

    get flagZ(): boolean {
        return this._coprocessor0.flagZ;
    }

    get consoleInput(): string {
        return this._ioInput.inputBuffered;
    }

    set consoleInput(value: string) {
        this._ioInput.inputBuffered = value;
    }

    get consoleOutput(): string {
        return this._ioOutput.output;
    }

    get ioDeviceConsoleInput(): IoDeviceConsoleInput {
        return this._ioInput;
    }

    get ioDeviceConsoleOutput(): IoDeviceConsoleOutput {
        return this._ioOutput;
    }

    get memory(): Memory {
        return this._memory;
    }

    get coprocessor(): CoProcessor0 {
        return this._coprocessor0;
    }

    get alu(): Alu {
        return this._alu;
    }

    get nextInstruction(): Instruction {
        return this._inKernelMode ?
                this._osInstructions[(this._pc - DEFAULT_OS_START_ADDRESS) / 4] :
                this._textInstructions[(this._pc - this._textStartAddress) / 4] ;
    }

    get nextInstructionString(): string {
        return this.nextInstruction ? this.nextInstruction.toString() : "finished";
    }

    private get _inKernelMode(): boolean {
        return this._pc < this._textInstructions[0].address;
    }

    //#endregion

    constructor(textInstructions: Instruction[], osInstructions: Instruction[], memory: Memory, startPC: number, ioInput: IoDeviceConsoleInput, ioOutput: IoDeviceConsoleOutput) {
        this._initializeRegisters();
        this._pc = startPC;
        this._textStartAddress = startPC;
        this._textInstructions = textInstructions;
        this._osInstructions = osInstructions;
        this._memory = memory;
        this._ioInput = ioInput;
        this._ioOutput = ioOutput;
    }

    step(): void {
        if (this.nextInstruction) {
            this._executeInstruction(this.nextInstruction);
        }
    }

    interruptOccurred(): void {
        this.__pc = DEFAULT_OS_START_ADDRESS;
    }

    private _initializeRegisters(): void {
        for (let i: number = 0; i < REGISTER_AMOUNT; i++) {
            this._registers.push(new Register(i));
        }
        this._registers[RegisterName.SP].value = BigInt(STACK_LAST_ADDRESS - 7);
    }

    private _executeInstruction(instruction: Instruction): void {
        let newPC: number;
        switch (instruction.opcode) {
            //#region ======= Arithmetik =======
            case Opcode.ADDS:
                this._registers[instruction.rd].value = this._alu.adds(this._registers[instruction.r1].value, this._registers[instruction.r2].value);
                newPC = this._pc + 4;
                break;
            case Opcode.ADD:
                this._registers[instruction.rd].value = this._alu.add(this._registers[instruction.r1].value, this._registers[instruction.r2].value);
                newPC = this._pc + 4;
                break;
            case Opcode.ADDIS:
                this._registers[instruction.rd].value = this._alu.adds(this._registers[instruction.r1].value, instruction.immediate);
                newPC = this._pc + 4;
                break;
            case Opcode.ADDI:
                this._registers[instruction.rd].value = this._alu.add(this._registers[instruction.r1].value, instruction.immediate);
                newPC = this._pc + 4;
                break;
            case Opcode.SUBS:
                this._registers[instruction.rd].value = this._alu.subs(this._registers[instruction.r1].value, this._registers[instruction.r2].value);
                newPC = this._pc + 4;
                break;
            case Opcode.SUB:
                this._registers[instruction.rd].value = this._alu.sub(this._registers[instruction.r1].value, this._registers[instruction.r2].value);
                newPC = this._pc + 4;
                break;
            case Opcode.SUBIS:
                this._registers[instruction.rd].value = this._alu.subs(this._registers[instruction.r1].value, instruction.immediate);
                newPC = this._pc + 4;
                break;
            case Opcode.SUBI:
                this._registers[instruction.rd].value = this._alu.sub(this._registers[instruction.r1].value, instruction.immediate);
                newPC = this._pc + 4;
                break;
            case Opcode.MUL:
                this._registers[instruction.rd].value = this._alu.mul(this._registers[instruction.r1].value, this._registers[instruction.r2].value);
                newPC = this._pc + 4;
                break;
            case Opcode.SDIV:
                const value: bigint = this._alu.sdiv(this._registers[instruction.r1].value, this._registers[instruction.r2].value);
                this._registers[instruction.rd].value = (value !== undefined) ? value : this._registers[instruction.rd].value;
                newPC = this._pc + 4;
                break;
            //#endregion

            //#region ======= Logic =======
            case Opcode.AND:
                this._registers[instruction.rd].value = this._alu.and(this._registers[instruction.r1].value, this._registers[instruction.r2].value);
                newPC = this._pc + 4;
                break;
            case Opcode.ANDS:
                this._registers[instruction.rd].value = this._alu.ands(this._registers[instruction.r1].value, this._registers[instruction.r2].value);
                newPC = this._pc + 4;
                break;
            case Opcode.ANDI:
                this._registers[instruction.rd].value = this._alu.and(this._registers[instruction.r1].value, instruction.immediate);
                newPC = this._pc + 4;
                break;
            case Opcode.ANDIS:
                this._registers[instruction.rd].value = this._alu.ands(this._registers[instruction.r1].value, instruction.immediate);
                newPC = this._pc + 4;
                break;
            case Opcode.ORR:
                this._registers[instruction.rd].value = this._alu.orr(this._registers[instruction.r1].value, this._registers[instruction.r2].value);
                newPC = this._pc + 4;
                break;
            case Opcode.ORRI:
                this._registers[instruction.rd].value = this._alu.orr(this._registers[instruction.r1].value, instruction.immediate);
                newPC = this._pc + 4;
                break;
            case Opcode.EOR:
                this._registers[instruction.rd].value = this._alu.eor(this._registers[instruction.r1].value, this._registers[instruction.r2].value);
                newPC = this._pc + 4;
                break;
            case Opcode.EORI:
                this._registers[instruction.rd].value = this._alu.eor(this._registers[instruction.r1].value, instruction.immediate);
                newPC = this._pc + 4;
                break;
            case Opcode.ORN:
                this._registers[instruction.rd].value = this._alu.orn(this._registers[instruction.r1].value, this._registers[instruction.r2].value);
                newPC = this._pc + 4;
                break;
            //#endregion

            //#region ======= Shift =======
            case Opcode.LSL:
                this._registers[instruction.rd].value = this._alu.lsl(this._registers[instruction.r1].value, instruction.immediate);
                newPC = this._pc + 4;
                break;
            case Opcode.LSR:
                this._registers[instruction.rd].value = this._alu.lsr(this._registers[instruction.r1].value, instruction.immediate);
                newPC = this._pc + 4;
                break;
            case Opcode.ASR:
                this._registers[instruction.rd].value = this._alu.asr(this._registers[instruction.r1].value, instruction.immediate);
                newPC = this._pc + 4;
                break;
            case Opcode.ROR:
                this._registers[instruction.rd].value = this._alu.ror(this._registers[instruction.r1].value, instruction.immediate);
                newPC = this._pc + 4;
                break;
            case Opcode.LSLV:
                this._registers[instruction.rd].value = this._alu.lsl(this._registers[instruction.r1].value, BigInt.asUintN(6, this._registers[instruction.r2].value));
                newPC = this._pc + 4;
                break;
            case Opcode.LSRV:
                this._registers[instruction.rd].value = this._alu.lsr(this._registers[instruction.r1].value, BigInt.asUintN(6, this._registers[instruction.r2].value));
                newPC = this._pc + 4;
                break;
            case Opcode.RORV:
                this._registers[instruction.rd].value = this._alu.ror(this._registers[instruction.r1].value, BigInt.asUintN(6, this._registers[instruction.r2].value));
                newPC = this._pc + 4;
                break;
            //#endregion

            //#region ======= Datatransport =======
            case Opcode.LDUR:
                {
                    this._load(instruction, 8);
                    newPC = this._pc + 4;
                    break;
                }
            case Opcode.LDURW:
                {
                    this._load(instruction, 4);
                    newPC = this._pc + 4;
                    break;
                }
            case Opcode.LDURH:
                {
                    this._load(instruction, 2);
                    newPC = this._pc + 4;
                    break;
                }
            case Opcode.LDURB:
                {
                    this._load(instruction, 1);
                    newPC = this._pc + 4;
                    break;
                }
            case Opcode.STUR:
                {
                    const address: number = this._toAddress(this._registers[instruction.r1].value, instruction.immediate);
                    this._store(address, this._registers[instruction.rd].value, 8);
                    newPC = this._pc + 4;
                    break;
                }
            case Opcode.STURW:
                {
                    const address: number = this._toAddress(this._registers[instruction.r1].value, instruction.immediate);
                    this._store(address, this._registers[instruction.rd].value, 4);
                    newPC = this._pc + 4;
                    break;
                }
            case Opcode.STURH:
                {
                    const address: number = this._toAddress(this._registers[instruction.r1].value, instruction.immediate);
                    this._store(address, this._registers[instruction.rd].value, 2);
                    newPC = this._pc + 4;
                    break;
                }
            case Opcode.STURB:
                {
                    const address: number = this._toAddress(this._registers[instruction.r1].value, instruction.immediate);
                    this._store(address, this._registers[instruction.rd].value, 1);
                    newPC = this._pc + 4;
                    break;
                }
            case Opcode.MOVZ:
                this._registers[instruction.rd].value = this._alu.lsl(instruction.immediate, BigInt(instruction.shiftAmount));
                newPC = this._pc + 4;
                break;
            case Opcode.MOVK:
                const mask: bigint = BigInt.asIntN(64, BigInt(
                    instruction.shiftAmount === 0? "0xffffffffffff0000" :
                    (instruction.shiftAmount === 16 ? "0xffffffff0000ffff" :
                    (instruction.shiftAmount === 32 ? "0xffff0000ffffffff" : "0x0000ffffffffffff"))));
                const rdMaskedOut: bigint = BigInt.asUintN(64, this._registers[instruction.rd].value) & mask;
                const immediatShifted: bigint = BigInt.asUintN(16, instruction.immediate) << BigInt.asUintN(6, BigInt(instruction.shiftAmount));
                this._registers[instruction.rd].value = this._alu.add(rdMaskedOut, immediatShifted);
                newPC = this._pc + 4;
                break;
            //#endregion

            //#region ======= Branch =======
            case Opcode.B:
                newPC = this._pc + Number(instruction.immediate) * 4;
                break;
            case Opcode.BR:
                newPC = Number(this._registers[instruction.r1].value);
                break;
            case Opcode.BL:
                this._registers[RegisterName.LR].value = BigInt(this._pc + 4);
                newPC = this._pc + Number(instruction.immediate) * 4;
                break;
            case Opcode.CBZ:
                if (this._registers[instruction.rd].value === BigInt(0)) {
                    newPC = this._pc + Number(instruction.immediate) * 4;
                } else {
                    newPC = this._pc + 4;
                }
                break;
            case Opcode.CBNZ:
                if (this._registers[instruction.rd].value !== BigInt(0)) {
                    newPC = this._pc + Number(instruction.immediate) * 4;
                } else {
                    newPC = this._pc + 4;
                }
                break;
            case Opcode.BEQ:
                if (this.flagZ) {
                    newPC = this._pc + Number(instruction.immediate) * 4;
                } else {
                    newPC = this._pc + 4;
                }
                break;
            case Opcode.BNE:
                if (!this.flagZ) {
                    newPC = this._pc + Number(instruction.immediate) * 4;
                } else {
                    newPC = this._pc + 4;
                }
                break;
            case Opcode.BLT:
                if (this.flagN !== this.flagV) {
                    newPC = this._pc + Number(instruction.immediate) * 4;
                } else {
                    newPC = this._pc + 4;
                }
                break;
            case Opcode.BLE:
                if (!(!this.flagZ && this.flagN === this.flagV)) { // Skript Teil 1 Kapitel 5.4
                    newPC = this._pc + Number(instruction.immediate) * 4;
                } else {
                    newPC = this._pc + 4;
                }
                break;
            case Opcode.BGT:
                if ((!this.flagZ && this.flagN === this.flagV)) { // Skript Teil 1 Kapitel 5.4
                    newPC = this._pc + Number(instruction.immediate) * 4;
                } else {
                    newPC = this._pc + 4;
                }
                break;
            case Opcode.BGE:
                if (this.flagN === this.flagV) {
                    newPC = this._pc + Number(instruction.immediate) * 4;
                } else {
                    newPC = this._pc + 4;
                }
                break;
            //#endregion

            //#region ======= Rest =======
            case Opcode.SVC:
                switch (this._registers[RegisterName.A7].value) {
                    case BigInt(1):
                        this._ioOutput.print(valueToString(this._registers[RegisterName.A0].value, -10, 8));
                        break;
                    case BigInt(4):
                        const str: string = this._memory.loadAsString(Number(BigInt.asUintN(48, this._registers[RegisterName.A0].value)));
                        this._ioOutput.print(str);
                        break;
                    case BigInt(5):
                        const consumed: string = this._ioInput.consumeInput();
                        try {
                            this._registers[RegisterName.A0].value = BigInt.asUintN(64, BigInt(consumed));
                        } catch (SyntaxError) {
                            this._registers[RegisterName.A0].value = BigInt(0);
                            throw new TypeError(`Cannot read "${consumed}" as integer.`);
                        }
                        break;
                    case BigInt(10):
                        this._pc = 0;
                        break;
                }
                newPC = this._pc + 4;
                break;
            case Opcode.MSR:
                if (instruction.r2 === 12) this._coprocessor0.status = this._registers[instruction.rd].value;
                if (instruction.r2 === 13) this._coprocessor0.cause = Number(BigInt.asUintN(4, this._registers[instruction.rd].value));
                if (instruction.r2 === 14) this._coprocessor0.elr = Number(BigInt.asUintN(48, this._registers[instruction.rd].value));
                newPC = this._pc + 4;
                break;
            case Opcode.MRS:
                if (instruction.r2 === 12) this._registers[instruction.rd].value = this._coprocessor0.status;
                if (instruction.r2 === 13) this._registers[instruction.rd].value = BigInt(this._coprocessor0.cause);
                if (instruction.r2 === 14) this._registers[instruction.rd].value = BigInt(this._coprocessor0.elr);
                newPC = this._pc + 4;
                break;
            case Opcode.RFE:
                this._coprocessor0.resetIn(1);
                newPC = this._pc + 4;
                break;
            case Opcode.ERET:
                this._coprocessor0.resetIn(0);
                newPC = Number(this._registers[RegisterName.IP0].value);
                break;
            //#endregion
            default:
                throw new Error("Execution of " + instruction.opcode + " instruction is not defined.");
        }
        this.registers[31].value = BigInt(0);

        this._setNewPC(newPC);
    }

    private _toAddress(valueA: bigint, valueB: bigint): number {
        return Number(BigInt.asUintN(48, this._alu.add(valueA, valueB)));
    }

    private _load(instruction: Instruction, byteAmount: number): void {
        const address: number = this._toAddress(this._registers[instruction.r1].value, instruction.immediate);
        try {
            const value: bigint = this._memory.load(address, byteAmount, this._inKernelMode);
            this._registers[instruction.rd].value = (value !== undefined) ? value : this._registers[instruction.rd].value;
        } catch (error) {
            if (error instanceof OsReadError) {
                this.coprocessor.registerInterrupt(4);
            } else {
                throw error;
            }
        }
    }

    private _store(address: number, value: bigint, byteAmount: number): void {
        try {
            this._memory.store(address, value, byteAmount, this._inKernelMode);
        } catch (error) {
            if (error instanceof ReadonlyError) {
                this.coprocessor.registerInterrupt(5);
            } else {
                throw error;
            }
        }
    }

    private _setNewPC(calculatedPC: number): void {
        if (!this._coprocessor0.didSetPC()) {
            this._pc = calculatedPC;
        }
    }
}