import { CompileError } from './CompileError';
import { RegisterName } from './registerName_enum';
import CompilerSegmentInterface from "./CompilerSegmentInterface";
import Compiler from "./Compiler";
import { REGEXP_IMMEDIATE, REGEXP_DOT_BYTE_WORD_DOUBLEWORD_LABEL, REGEXP_DOT_BYTE_WORD_DOUBLEWORD_CHAR } from './../../frontend-arm-simulator/src/cm-mode-MiniARM';
import Memory from './Memory';

export default class CompilerSegmentData implements CompilerSegmentInterface {
    private _compiler: Compiler;

    private _startAddress: number;

    private _currentDataOffset: number = 0;
    private _data: {byteAmount: number, offset: number, values: bigint[]}[] = [];
    private _labels: {label: string, offset: number, onErrorLineInEditor: number}[] = [];

    private _addressesWithLabelAsValue: {byteAmount: number, offset: number, label: string, onErrorLineInEditor: number}[] = [];

    get sizeInByte(): number {
        return this._currentDataOffset;
    }

    get startAddress(): number {
        return this._startAddress;
    }

    set startAddress(value: number) {
        this._startAddress = value;
    }

    constructor(compiler: Compiler) {
        this._compiler = compiler;
        // empty
    }

    compile(tokens: RegExpMatchArray, comment: string, lineInEditor: number): void {
        this._rememberLabel(Compiler.seperateLabel(tokens[2]));
        switch (tokens[4]) {
            case '.space':
                {
                    const numberOfBytes: number = Number(tokens[6]);
                    if (numberOfBytes < 1) {
                        throw new CompileError(this._compiler.onErrorCurrentLineInEditor, "Only positive numbers greater 0 are allowed as .space argument.");
                    }
                    this._currentDataOffset += numberOfBytes;
                    break;
                }
            case '.align':
                {
                    const argument: number = Number(tokens[6]);
                    if (![0, 1, 2, 3].includes(argument)) {
                        throw new CompileError(this._compiler.onErrorCurrentLineInEditor, ".align argument must be one of [0, 1, 2, 3]");
                    }
                    const alignBorder: number = 2 ** argument;
                    if (this._currentDataOffset % alignBorder !== 0) {
                        this._currentDataOffset += alignBorder - (this._currentDataOffset % alignBorder);
                    }
                    break;
                }
            case '.byte':
                {
                    if (this._byteWordDoubleWordWithLabel(tokens.input)) {
                        this._rememberAddressAndLabel(tokens[6], 1);
                    } else if (this._byteWordDoubleWordWithChar(tokens.input)) {
                        this._insertChar(tokens[6], 1);
                    } else {
                        this._insertListOfData(tokens[5], 1);
                    }
                    break;
                }
            case '.word':
                {
                    if (this._byteWordDoubleWordWithLabel(tokens.input)) {
                        this._rememberAddressAndLabel(tokens[6], 4);
                    } else if (this._byteWordDoubleWordWithChar(tokens.input)) {
                        this._insertChar(tokens[6], 4);
                    } else {
                        this._insertListOfData(tokens[5], 4);
                    }
                    break;
                }
            case '.doubleword':
                {
                    if (this._byteWordDoubleWordWithLabel(tokens.input)) {
                        this._rememberAddressAndLabel(tokens[6], 8);
                    } else if (this._byteWordDoubleWordWithChar(tokens.input)) {
                        this._insertChar(tokens[6], 8);
                    } else {
                        this._insertListOfData(tokens[5], 8);
                    }
                    break;
                }
            case '.asciiz':
                {
                    const byteAmount: number = 1;
                    const values: bigint[] = this._charsToBigInts(tokens[6].slice(1, -1) + "\0");
                    const offset: number = this._currentDataOffset;
                    this._data.push({byteAmount, offset, values});
                    this._currentDataOffset += values.length;
                    break;
                }
            case '.globl':
                {
                    // does nothing
                    break;
                }
            case '.extern':
                {
                    // does nothing
                    break;
                }
            default:
                {
                    throw new CompileError(this._compiler.onErrorCurrentLineInEditor, `Unknown directive "${tokens[4]}"`);
                }
        }
        this._compiler.incrementCurrentLineInEditor();
    }

    fillMemory(memory: Memory): void {
        this._data.forEach((entry): void => {
            for (let i: number = 0; i < entry.values.length; i++) {
                memory.store(this._startAddress + entry.offset + i * entry.byteAmount, entry.values[i], entry.byteAmount, true);
            }
        });
    }

    getLabels(): {label: string, address: number, onErrorLineInEditor: number}[] {
        const result: {label: string, address: number, onErrorLineInEditor: number}[] = [];
        this._labels.forEach(({label, offset, onErrorLineInEditor}):void => {
            const address: number = this._startAddress + offset;
            result.push({label, address, onErrorLineInEditor});
        });
        return result;
    }

    phase2(labelTable: Map<string, number>): void {
        this._addressesWithLabelAsValue.forEach(({byteAmount, offset, label, onErrorLineInEditor}):void => {
            if (labelTable.has(label)) {
                const labelsAddress: bigint = BigInt(labelTable.get(label));
                const values: bigint[] = [BigInt.asUintN(byteAmount * 8, labelsAddress)];
                this._data.push({byteAmount, offset, values});
            } else {
                throw new CompileError(onErrorLineInEditor, `The label ${label} is not defined.`);
            }
        });
        return;
    }

    private _rememberLabel(label: string): void {
        if (label !== "") {
            const offset: number = this._currentDataOffset;
            const onErrorLineInEditor: number = this._compiler.onErrorCurrentLineInEditor;
            this._labels.push({label, offset, onErrorLineInEditor});
        }
    }

    private _stringOfValuesToImmediates(valuesString: string, byteAmount: number): bigint[] {
        let matchArray: RegExpMatchArray = valuesString.match(REGEXP_IMMEDIATE);
        const values: bigint[] = [];
        while (matchArray) {
            values.push(this._compiler.toImmediate(matchArray[1], byteAmount * 8, true));
            valuesString = valuesString.replace(matchArray[1], "");
            matchArray = valuesString.match(REGEXP_IMMEDIATE);
        }
        return values;
    }

    private _charsToBigInts(str: string): bigint[] {
        const result: bigint[] = [];
        let resultIndex: number = 0;
        for (let i: number= 0; i < str.length; i++) {
            result[resultIndex] = BigInt.asUintN(8, BigInt(str.charCodeAt(i)));
            if (str[i] === "\\" && str[i+1] === "n") {
                result[resultIndex] = BigInt.asUintN(8, BigInt("\n".charCodeAt(0)));
                i++;
            } else if (str[i] === "\\" && str[i+1] === `"`) {
                result[resultIndex] = BigInt.asUintN(8, BigInt("\"".charCodeAt(0)));
                i++;
            } else if (str[i] === "\\" && str[i+1] === `t`) {
                result[resultIndex] = BigInt.asUintN(8, BigInt("\t".charCodeAt(0)));
                i++;
            }
            resultIndex++;
        }
        return result;
    }

    private _byteWordDoubleWordWithLabel(str: string): boolean {
        return (str.match(REGEXP_DOT_BYTE_WORD_DOUBLEWORD_LABEL) !== null);
    }

    private _byteWordDoubleWordWithChar(str: string): boolean {
        return (str.match(REGEXP_DOT_BYTE_WORD_DOUBLEWORD_CHAR) !== null);
    }

    private _rememberAddressAndLabel(label: string, byteAmount: number): void {
        const offset: number = this._currentDataOffset;
        const onErrorLineInEditor: number = this._compiler.onErrorCurrentLineInEditor;
        this._addressesWithLabelAsValue.push({byteAmount, offset, label, onErrorLineInEditor});
        this._currentDataOffset += byteAmount;
    }

    private _insertChar(charAsString: string, byteAmount: number): void {
        let values: bigint[];
        if (charAsString.length === 3) {
            values = [BigInt(charAsString.charCodeAt(1))];
        } else {
            values = [BigInt((charAsString[2] === 'n' ? '\n' :
                                charAsString[2] === 't' ? '\t' : '\0').charCodeAt(0))];
        }
        const offset: number = this._currentDataOffset;
        this._data.push({byteAmount, offset, values});
        this._currentDataOffset += values.length * byteAmount;
    }

    private _insertListOfData(stringOfValues: string, byteAmount: number): void {
        const values: bigint[] = this._stringOfValuesToImmediates(stringOfValues, byteAmount);
        const offset: number = this._currentDataOffset;
        this._data.push({byteAmount, offset, values});
        this._currentDataOffset += values.length * byteAmount;
    }
}