import { DEFAULT_OS_START_ADDRESS } from './Memory';
import Processor from './Processor';

const CAUSE_TO_PRIORITY: number[] = [3,2,,,1,1,,,,,,,1,1];
/* cause numbers:
 *  0: extern interrupt
 *  1: timer interrupt
 *  4: error on reading memory (reading in OS memory)
 *  5: error on writing memory (writing in OS or text memory)
 * 12: overflow
 * 13: zero division
 * -1: default, no interrupt
 */

const DEFAULT_MASK: number = 0b1111;

export default class CoProcessor0 {
    flagC: boolean = false;
    flagN: boolean = false;
    flagV: boolean = false;
    flagZ: boolean = false;

    cause: number = -1; // 4-bit wide
    elr: number = 0;
    private _mask: number = DEFAULT_MASK;
    private _currentPriority: number = 5; // 4-bit wide

    oldStatus: bigint = BigInt(0);

    private _detected: boolean = false;
    private _stepsTillReset: number = -1;
    private _processor: Processor;

    private nonTimerInterruptOccurred: boolean = false;

    //#region getter setter

    private get _flagsAsBigInt(): bigint {
        let result: bigint = BigInt(0);
        result += this.flagC ? BigInt('0b1000') : BigInt(0);
        result += this.flagN ? BigInt('0b0100') : BigInt(0);
        result += this.flagV ? BigInt('0b0010') : BigInt(0);
        result += this.flagZ ? BigInt('0b0001') : BigInt(0);
        return result;
    }

    private set _flagsAsBigInt(value: bigint) {
        value = BigInt.asUintN(4, value);
        this.flagC = (value & BigInt('0b1000')) > BigInt(0);
        this.flagN = (value & BigInt('0b0100')) > BigInt(0);
        this.flagV = (value & BigInt('0b0010')) > BigInt(0);
        this.flagZ = (value & BigInt('0b0001')) > BigInt(0);
    }

    get mask(): number {
        return this._mask;
    }

    get currentPriority(): number {
        return this._currentPriority;
    }

    get status(): bigint {
        let result: bigint = (BigInt(this._mask) << BigInt(60));
        result += this._flagsAsBigInt << BigInt(16);
        result += BigInt(this._currentPriority);
        return result;
    }

    set status(value: bigint) {
        this._currentPriority = Number(BigInt.asUintN(4, value));
        this._flagsAsBigInt = BigInt.asUintN(20, value) >> BigInt(16);
        this._mask = Number(BigInt.asUintN(64, value) >> BigInt(60))
    }

    get hasNewInterrupt(): boolean {
        const result: boolean = (this._mask !== DEFAULT_MASK && !this._detected);
        this._detected = result ? true : this._detected;
        return result;
    }

    //#endregion

    constructor(processor: Processor) {
        this._processor = processor;
    }

    step(): void {
        if (this._stepsTillReset > 0) this._stepsTillReset--;

        if (this._stepsTillReset === 0) this._reset();

        this.nonTimerInterruptOccurred = false;
    }

    resetIn(stepsTillReset: number): void {
        this._stepsTillReset = stepsTillReset + 1;
    }

    registerInterrupt(cause: number): void {
        const priority: number = CAUSE_TO_PRIORITY[cause];
        if (this._mask !== DEFAULT_MASK) {
            return;
            // throw new Error(`Interrupt occurred during handling another interrupt. New interrupt ignored. \n cause: ${cause}\n pc:    ${this._processor.pc}`);
        }

        this.nonTimerInterruptOccurred = cause !== 1;

        this.oldStatus = this.status;
        this.cause = cause;
        this.elr = this._processor.pc;
        this._mask = this._priorityToMask(priority);
        this._currentPriority = priority;
        this._processor.interruptOccurred();
    }

    didSetPC(): boolean {
        if (this.nonTimerInterruptOccurred) {
            this.nonTimerInterruptOccurred = false;
            return true;
        } else {
            return false;
        }
    }

    private _reset(): void {
        this.cause = -1;
        this.status = this.oldStatus;
        this._detected = false;
        this._stepsTillReset = -1;
    }

    private _priorityToMask(priority: number): number {
        if (priority === 1) return 0b0000;
        if (priority === 2) return 0b1000;
        if (priority === 3) return 0b1100;
        if (priority === 4) return 0b1110;
    }
}