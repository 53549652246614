export function valueToString(value: number | bigint, base: number, byteAmount: number, hideZeros: boolean = false): string {
    let result: string;

    if (base === -10) {
        result = BigInt.asIntN(byteAmount * 8, BigInt(value)).toString(10);
    } else {
        result = BigInt.asUintN(64, BigInt(value)).toString(base);

        // addLeadingZeros
        if (base === 16) {
            result = ("0".repeat(byteAmount*2 -1) + result).substr(-2 * byteAmount);
        }
        if (base === 2) {
            result = ("0".repeat(byteAmount*8 -1) + result).substr(-8 * byteAmount);
        }
    }

    // replace 0
    if (hideZeros) {
        result = result.replace(/0/g, ".");
    }

    return result;
}