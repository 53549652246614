<!-- options -->
<div class="register-buttons">
  <!-- base -->
  <div class="switch-toggle no-copy" id="base" title="change base">
  
    <div class="switch-toggle-rectangle" id="base-rectangle"></div>

    <input id="register-2" name="register-base" type="radio" checked="" />
    <label for="register-2" (click)="setBase(2)" title="show values binary">2</label>
  
    <input id="register-10" name="register-base" type="radio" checked="" />
    <label for="register-10" class="disabled" (click)="setBase(10)" title="show values unsigned integer">ℕ</label>
  
    <input id="register--10" name="register-base" type="radio" checked="" />
    <label for="register--10" class="disabled" (click)="setBase(-10)" title="show values integer">ℤ</label>
  
    <input id="register-16" name="register-base" type="radio" checked="checked" />
    <label for="register-16" (click)="setBase(16)" title="show values hexadecimal">16</label>
  
  </div>

  <!-- zero representation -->
  <div class="switch-toggle no-copy" id="zero" title="change zero representation">
  
    <div class="switch-toggle-rectangle" id="zero-rectangle"></div>

    <input id="register-hide" name="register-zeros" type="radio" checked="" />
    <label for="register-hide" (click)="setHideZeros(true)" title="hide zeros">.</label>
  
    <input id="register-show" name="register-zeros" type="radio" checked="checked" />
    <label for="register-show" class="disabled" (click)="setHideZeros(false)" title="show zeros">0</label>
  
  </div>

</div>

<!-- values -->
<div class="arm-sim-box "> 

  <h2>Registers</h2>

  <div id="register-grid">
    <div class="arm-sim-left-column spacer"> </div>
    <div class="register-value spacer"> </div>

    <ng-container *ngFor="let r of registers">
      <div class="arm-sim-left-column">{{ r.nameAlt }}{{ r.nameAlt.length === 2 ? " " : "" }} ({{ r.nameX }})</div>
      <div class="register-value">{{ r.toString(base, hideZeros) }}</div>
    </ng-container>

    <div class="arm-sim-left-column spacer"> </div>
    <div class="register-value spacer"> </div>
  </div>

</div>
