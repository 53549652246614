import { ShiftAmount } from './shift_amount_enum';
import { RegisterName } from './registerName_enum';
import { InstructionLookup, opcodeToInstructionLookup } from './instruction_lookup_table';
import { Format } from './format_enum';
import { Opcode } from './opcode_enum';

const REGEX_SPACES: RegExp = /\s/;

export default class Instruction {
    private _address: number;
    private _label: string;
    private _comment: string;

    private _opcode: Opcode;
    private _opcodeString: string;
    private _opcodeValue: number;
    private _format: Format;

    private _rd: RegisterName;
    private _r1: RegisterName;
    private _r2: RegisterName;
    private _immediate: bigint;
    private _shiftAmount: ShiftAmount;
    private _argumentLabel: string;

    private _lineInEditor: number;
    private _onErrorLineInEditor: number;

    private _instructionAsString: string;

    //#region getter setter

    get address(): number {
        return this._address;
    }

    get label(): string {
        return this._label;
    }

    get comment(): string {
        return this._comment;
    }

    get opcode(): Opcode {
        return this._opcode;
    }

    get opcodeString(): string {
        return this._opcodeString;
    }

    get format(): Format {
        return this._format;
    }

    get opcodeValue(): number {
        return this._opcodeValue;
    }

    get r1(): number {
        return this._r1;
    }

    get r2(): number {
        return this._r2;
    }

    get rd(): number {
        return this._rd;
    }

    get immediate(): bigint {
        return this._immediate;
    }

    set immediate(value: bigint) {
        this._immediate = value;
        this._calcString();
    }

    get shiftAmount(): ShiftAmount {
        return this._shiftAmount;
    }

    get argumentLabel(): string {
        return this._argumentLabel;
    }

    get lineInEditor(): number {
        return this._lineInEditor;
    }

    get onErrorLineInEditor(): number {
        return this._onErrorLineInEditor;
    }

    //#endregion

    constructor(
        address: number,
        label: string,
        format: Format,
        opcode: Opcode,
        opcodeString: string,
        opcodeValue: number,
        comment: string,
        rd: RegisterName,
        r1: RegisterName,
        r2: RegisterName,
        immediate: bigint,
        shiftAmount: ShiftAmount,
        argumentLabel: string,
        lineInEditor: number,
        onErrorLineInEditor: number
    ) {
        this._address = address;
        this._label = label;
        this._format = format;
        this._opcode = opcode;
        this._opcodeString = opcodeString;
        this._opcodeValue = opcodeValue;
        this._comment = comment;
        this._rd = rd;
        this._r1 = r1;
        this._r2 = r2;
        this._immediate = immediate;
        this._shiftAmount = shiftAmount;
        this._argumentLabel = argumentLabel;

        this._lineInEditor = lineInEditor;
        this._onErrorLineInEditor = onErrorLineInEditor;

        this._calcString();
    }

    getAsBinary(): bigint {
        let result: bigint = BigInt(0);
        switch (this._format) {
            case Format.B_FORMAT:
                result += this._shiftBits(this._opcodeValue,  6, 26);
                result += this._shiftBits(this._immediate,   26,  0);
                break;
            case Format.CB_FORMAT:
                result += this._shiftBits(this._opcodeValue,  8, 24);
                result += this._shiftBits(this._immediate,   19,  5);
                result += this._shiftBits(this._rd,           5,  0);
                break;
            case Format.D_FORMAT:
                result += this._shiftBits(this._opcodeValue, 11, 21);
                result += this._shiftBits(this._immediate,    9, 12);
                result += this._shiftBits(this._r1,           5,  5);
                result += this._shiftBits(this._rd,           5,  0);
                break;
            case Format.IW_FORMAT:
                result += this._shiftBits(this._opcodeValue,   9, 23);
                result += this._shiftBits(this._shiftAmount / 16, 2, 21);
                result += this._shiftBits(this._immediate,    16, 5);
                result += this._shiftBits(this._rd,           5,  0);
                break;
            case Format.I_FORMAT:
                result += this._shiftBits(this._opcodeValue, 10, 22);
                result += this._shiftBits(this._immediate,   12, 10);
                result += this._shiftBits(this._r1,           5,  5);
                result += this._shiftBits(this._rd,           5,  0);
                break;
            case Format.R_FORMAT:
                result += this._shiftBits(this._opcodeValue, 11, 21);
                result += this._shiftBits(this._r2,           5, 16);
                result += this._shiftBits(this._r1,           5,  5);
                result += this._shiftBits(this._rd,           5,  0);
                break;
            default:
                throw new Error("Binary of " + this._opcode + " instructions unknown.");
        }
        return result;
    }

    private _shiftBits(value: any, bitAmount: number, shiftAmount: number): bigint {
        return BigInt.asUintN(32, BigInt.asUintN(bitAmount, BigInt(value)) << BigInt(shiftAmount));
    }

    toString(): string {
        return this._instructionAsString;
    }

    private _calcString(): void {
        switch (this._opcode) {
            case Opcode.RFE:
            case Opcode.ERET:
            case Opcode.SVC:
                this._instructionAsString = this._opcodeString;
                return;
            case Opcode.BR:
                this._instructionAsString = this._opcodeString + " X" + this._r1;
                return;
            case Opcode.MRS:
                this._instructionAsString = this._opcodeString + " X" + this._rd + ", " + (this._r2 === 13 ? "cause" : (this._r2 === 14 ? "elr" : "status"));
                return;
            case Opcode.MSR:
                this._instructionAsString = this._opcodeString + " " + (this._r2 === 13 ? "cause" : (this._r2 === 14 ? "elr" : "status")) + ", X" + this._rd;
                return;
            case Opcode.BEQ:
            case Opcode.BNE:
            case Opcode.BLT:
            case Opcode.BLE:
            case Opcode.BGT:
            case Opcode.BGE:
                this._instructionAsString = this._opcodeString + " " + this._immediate;
                return;
        }

        this._instructionAsString = this._opcodeString + " ";
        switch (this._format) {
            case Format.B_FORMAT:
                this._instructionAsString += this._immediate;
                break;
            case Format.CB_FORMAT:
                this._instructionAsString += "X" + this._rd + ", " + this._immediate;
                break;
            case Format.D_FORMAT:
                this._instructionAsString += "X" + this._rd + ", [X" + this._r1 + ", #" + this._immediate + "]";
                break;
            case Format.I_FORMAT:
                this._instructionAsString += "X" + this._rd + ", X" + this._r1 + ", #" + this._immediate;
                break;
            case Format.IW_FORMAT:
                this._instructionAsString += "X" + this._rd + ", #" + this._immediate + ", LSL" + this._shiftAmount;
                break;
            case Format.R_FORMAT:
                this._instructionAsString += "X" + this._rd + ", X" + this._r1 + ", X" + this._r2;
                break;
        }
    }
}