import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RegistersComponent } from './registers/registers.component';
import { MemoryComponent } from './memory/memory.component';
import { CodeComponent } from './code/code.component';
import { ConsoleComponent } from './console/console.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { FormsModule } from '@angular/forms';
import { MemoryRegionComponent } from './memory-region/memory-region.component';
import { AluFlagsComponent } from './alu-flags/alu-flags.component';
import { CoprocessorComponent } from './coprocessor/coprocessor.component';
import { TimerComponent } from './timer/timer.component';
import { APP_BASE_HREF, LocationStrategy, HashLocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    RegistersComponent,
    MemoryComponent,
    CodeComponent,
    ConsoleComponent,
    MemoryRegionComponent,
    AluFlagsComponent,
    CoprocessorComponent,
    TimerComponent
   ],
  imports: [
    BrowserModule,
    FormsModule,
    CodemirrorModule,
    MatIconModule
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
