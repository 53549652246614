// export const DEFAULT_INTERRUPT_HANDLER: string =
// 	`.kernel_text \n MRS IP0, ELR \n ADDI IP0, IP0, #4 \n RFE \n BR IP0 \n .kernel_data`;


export const DEFAULT_INTERRUPT_HANDLER: string =
`


		.kernel_text
		// Since we are running in kernel, we can use IP0, IP1 without
		// saving their old values
		MOV IP0, XR			// save XR
		STUR IP0, __s1_		// save XR
		STUR A0, __s2_		// save A0
		STUR A7, __s3_		// save A7
		MRS IP1, cause		// read cause
		MRS IP0, ELR		// read ELR
		CBZ IP1, ret		// ignore interrupt exceptions
		LI A7, #4			// svc 4 (print_str)
		LA A0, __m1_		// address of string constant "Exception"
		SVC
		LI A7, #1			// svc 1 (print_int)
		MOV A0, IP1			// argument: exception code
		SVC
		LI A7, #4			// svc 4 (print_str)
		LSL A0, IP1, #2		// address of exception type string constant
		LDURW A0, __ext_ + [A0, #0]
		SVC
		ANDI A0, IP0, #0x3	// check whether PC (ELR) is word aligned
		CBZ A0, ok_pc
		LI A7, #10			// svc 10: exit
		SVC
ok_pc:	LI A7, #4			// svc 4 (print_str)
		LA A0, __m2_		// address of string constant "occurred and ignored.\\n"
		SVC
		MOVZ A0, #0x000f, LSL0	// clear cause register
		MSR cause, A0
ret:	LDUR A7, __s3_
		LDUR A0, __s2_
		LDUR XR, __s1_

		CMP IP1, #1 		// all causes less or equal 1 need to execute ELR
		B.LE skip_4			// if so then skip adding 4
		ADDI IP0, IP0, #4	// return to next instruction
skip_4:	RFE					// change to user mode
		BR IP0


		.kernel_data
__m1_:	.asciiz "  Exception "
__m2_:	.asciiz " occurred and ignored\\n"
__e0_:	.asciiz "  [Interrupt] "
__e1_:	.asciiz	"  [Timer interrupt] "
__e2_:	.asciiz	""
__e3_:	.asciiz	""
__e4_:	.asciiz	"  [Bad address read] "
__e5_:	.asciiz	"  [Bad address write] "
__e6_:	.asciiz	""
__e7_:	.asciiz	""
__e8_:	.asciiz	""
__e9_:	.asciiz	""
__e10_:	.asciiz	""
__e11_:	.asciiz	""
__e12_:	.asciiz	"  [Arithmetic overflow] "
__e13_:	.asciiz	"  [Divide by 0] "
		.align 2
__ext_:	.word __e0_		// table with text addresses
		.word __e1_
		.word __e2_
		.word __e3_
		.word __e4_
		.word __e5_
		.word __e6_
		.word __e7_
		.word __e8_
		.word __e9_
		.word __e10_
		.word __e11_
		.word __e12_
		.word __e13_
		.align 3
__s1_:	.doubleword 0	// mini interrupt work area
__s2_:	.doubleword 0	// for storing registers used
__s3_:	.doubleword 0	// by the exception handler

`;