import IoDeviceInterface from "./IoDeviceInterface";
import { valueToString } from "./GlobalFunction";

export const REGISTER_SIZE_IN_BYTE: number = 8; // should be in [1,2,4,8]; currently 8, so no problem with LDUR / STUR instructions writing in multiple registers
export const CONSOLE_STEP_AMOUNT: number = 3; // means the next #value amount of instructions no input is read or output printed respectively

export default class IoDeviceConsoleInput implements IoDeviceInterface {
    sizeInByte: number = 2 * REGISTER_SIZE_IN_BYTE;

    private _inputBuffered: string = "";
    private _stepsTillConsume: number = CONSOLE_STEP_AMOUNT;

    private _receiverReady: boolean = false; // true, when input is ready in data and not read yet. Readonly.
    private _receiverData: number = 0; // Readonly. Last 8 Bit contain last char typed.

    get inputBuffered(): string {
        return this._inputBuffered;
    }

    set inputBuffered(str: string) {
        this._inputBuffered = str;
        this._stepsTillConsume = CONSOLE_STEP_AMOUNT;
        this._receiverReady = false;
    }

    consumeInput(): string {
        const result: string = this.inputBuffered;
        this.inputBuffered = "";
        this._receiverReady = false;
        return result;
    }

    step(): void {
        if (this._stepsTillConsume > 0) {
            this._stepsTillConsume--;
        } else if (this._stepsTillConsume === 0) {
            this._prepareNextCharToConsume();
            this._stepsTillConsume--;
        }
    }

    storeByte(offset: number, value: bigint): void {
        // all registers are readonly.
    }

    loadByte(offset: number): bigint {
        if (offset === REGISTER_SIZE_IN_BYTE - 1) {
            return BigInt.asUintN(8, BigInt(this._receiverReady ? 1 : 0));
        } else if (offset === 2 * REGISTER_SIZE_IN_BYTE - 1) {
            return this._loadData();
        }

        return BigInt(0);
    }

    toString(base: number, hideZeros: boolean): string[] {
        const result: string[] = [];

        for (let i: number = 0; i < REGISTER_SIZE_IN_BYTE - 1; i++) {
            result.push(valueToString(0, base, 1, hideZeros));
        }
        result.push(valueToString(this._receiverReady ? 1 : 0, base, 1, hideZeros));

        for (let i: number = 0; i < REGISTER_SIZE_IN_BYTE - 1; i++) {
            result.push(valueToString(0, base, 1, hideZeros));
        }
        result.push(valueToString(this._receiverData, base, 1, hideZeros));

        return result;
    }

    private _loadData(): bigint {
        if (this._receiverReady) {
            this._receiverReady = false;
            this._inputBuffered = this._inputBuffered.slice(1);
            this._stepsTillConsume = CONSOLE_STEP_AMOUNT;
        }
        return BigInt.asUintN(8, BigInt(this._receiverData));
    }

    private _prepareNextCharToConsume(): void {
        this._receiverData = (this._inputBuffered === "") ? 0 : this._inputBuffered.charCodeAt(0);
        this._receiverReady = true;
    }

}