import { RegisterName } from './registerName_enum';
import { valueToString } from './GlobalFunction';
export default class Register {
    private _name: RegisterName;
    private _value: bigint;

    get value(): bigint {
        return this._value;
    }

    set value(value: bigint) {
        this._value = value;
    }

    get name(): RegisterName {
        return this._name;
    }

    get nameX(): string {
        const nameX: string = this._name.toString();
        return "X" + this._name.toString() + (nameX.length === 1 ? " " : "");
    }

    get nameAlt(): string {
        return RegisterName[this._name];
    }

    constructor(name: RegisterName) {
        this._name = name;
        this._value = BigInt(0);
    }

    toString(base: number, hideZeros: boolean = false): string {
        return valueToString(this._value, base, 8, hideZeros);
    }
}