<div class="arm-sim-box">
  <h2>Coprocessor</h2>

  <div id="coprocessor-grid">
    <div class="arm-sim-left-column">Cause</div>
    <div class="coprocessor-value">{{ _cause }}</div>
    <div class="arm-sim-left-column">ELR</div>
    <div class="coprocessor-value">{{ _elr }}</div>
    <div class="arm-sim-left-column">Status</div>
    <div class="coprocessor-value">{{ _status }}</div>
    <div class="arm-sim-left-column">OldStatus</div>
    <div class="coprocessor-value">{{ _oldStatus }}</div>
  </div>
</div>